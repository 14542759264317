export const storeDashboardDetails = (data) => {
  return {
    type: STORE_DASHBOARD_DETAILS,
    payload: {
      ...data,
    },
  };
};

export const STORE_DASHBOARD_DETAILS = 'STORE_DASHBOARD_DETAILS';
