import React from 'react';
import moment from 'moment-timezone';

import styles from './EarningItem.module.scss';

const EarningItem = (props) => {
  const {
    earning,
    onClick,
    earning: { dateTime, earnedAmount, referenceCode, id, status },
  } = props;

  const handleOnEarningSelect = () => {
    onClick && onClick(earning);
  };

  const rootStyle = onClick
    ? [styles.root__div, styles.clickable].join(' ')
    : styles.root__div;
  return (
    <tr className={rootStyle} onClick={handleOnEarningSelect}>
      <td>
        {moment(dateTime).tz('Asia/Kolkata').format('DD/MM/YYYY hh:mm A')}
      </td>
      <td>{id.toUpperCase()}</td>
      {referenceCode !== null ? (
        <td>{referenceCode.toUpperCase()}</td>
      ) : (
        <td></td>
      )}
      <td>{status}</td>
      <td>
        <p className={styles.earned_amount_text}>{earnedAmount.toFixed(2)}</p>
      </td>
    </tr>
  );
};

EarningItem.defaultProps = {
  onClick: null,
};

export default EarningItem;
