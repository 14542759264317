import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import { addMerchantCategory } from '../../api/merchant';
import CancelAlertPopup from '../../components/CancelAlertPopup/CancelAlertPopup';

import './AddCategory.scss';

class AddCategory extends Component {
  state = {
    validated: false,
    name: '',
    imgUrl: '',
    description: '',
    apiErrorResponse: null,
    apiSuccessResponse: null,
    isSuccess: false,
    loading: false,
    isFailed: false,
    showCancelPopup: false,
    changes: false,
  };

  handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    this.setState({
      validated: true,
    });

    if (form.checkValidity()) {
      try {
        this.setState({
          loading: true,
          apiSuccessResponse: null,
          apiErrorResponse: null,
          isFailed: false,
          isSuccess: false,
        });
        const request = {
          name: this.state.name,
          imgUrl: this.state.imgUrl,
          description: this.state.description,
        };
        const response = await addMerchantCategory(request, this.props.token);
        this.setState({
          isSuccess: true,
          apiSuccessResponse: response.message,
          loading: false,
          isFailed: false,
        });
        this.props.getCategories();
      } catch (err) {
        this.setState({
          apiErrorResponse: err.message,
          loading: false,
          isFailed: true,
          apiSuccessResponse: null,
        });
      }
    }
  };

  handleClose = () => {
    if (this.state.changes && !this.state.isSuccess) {
      this.handleCancel();
    } else {
      this.setInitialValues();
      this.props.handleCloseModalShow();
    }
  };

  setInitialValues = () => {
    this.setState({
      isSuccess: false,
      apiSuccessResponse: null,
      apiErrorResponse: null,
      isFailed: false,
      name: '',
      imgUrl: '',
      description: '',
      validated: false,
      changes: false,
    });
  };

  handleInputChange = (event) => {
    let { name, value } = event.target;
    let item = this.state[name];
    if (item.length === 0) {
      value = value.trim();
    }
    this.setState({
      [name]: value,
      isSuccess: false,
      apiSuccessResponse: null,
      apiErrorResponse: null,
      isFailed: false,
      changes: true,
    });
  };

  handleClear = () => {
    this.setInitialValues();
    this.handleCancel();
    this.props.handleCloseModalShow();
  };

  handleCancel = () => {
    this.setState({
      showCancelPopup: !this.state.showCancelPopup,
    });
  };

  renderInputField = (name, label, value, onChange, max, as, required) => {
    return (
      <Form.Group as={Row}>
        <Col>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            name={name}
            value={value}
            onChange={onChange}
            maxLength={max}
            required={required === 'required'}
            placeholder={
              name === 'imgUrl'
                ? 'https://example.com/sample_image.png'
                : `Enter ${label.toLowerCase()}`
            }
            as={as}
          />
          <Form.Control.Feedback type='invalid'>
            {label} cannot be empty
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    );
  };

  renderSuccessResponse = () => {
    return <span className='success_msg'>{this.state.apiSuccessResponse}</span>;
  };

  renderErrorResponse = () => {
    return <span className='err_msg'>{this.state.apiErrorResponse}</span>;
  };

  renderModal = () => {
    const { showModal, handleModalShow } = this.props;
    return (
      <Modal
        show={showModal}
        onHide={handleModalShow}
        backdrop='static'
        keyboard={false}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>Create new merchant category here</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleSubmit}
          >
            {this.renderInputField(
              'name',
              'Name',
              this.state.name,
              this.handleInputChange,
              10,
              'input',
              'required'
            )}
            {this.renderInputField(
              'imgUrl',
              'Image URL',
              this.state.imgUrl,
              this.handleInputChange,
              240,
              'input',
              'required'
            )}
            {this.renderInputField(
              'description',
              'Description',
              this.state.description,
              this.handleInputChange,
              320,
              'textarea',
              'required'
            )}

            <div className='modal_footer'>
              <Button
                variant='secondary'
                onClick={this.handleClose}
                block
                className='footer_btn'
              >
                Close
              </Button>

              <Button
                variant='primary'
                type='submit'
                block
                className='footer_btn'
                disabled={this.state.loading}
              >
                {this.state.loading ? 'Loading' : 'Create'}
              </Button>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer />

        <div className='api_response_block'>
          {this.state.isSuccess
            ? this.renderSuccessResponse()
            : this.state.isFailed
            ? this.renderErrorResponse()
            : null}
        </div>
      </Modal>
    );
  };

  render() {
    return (
      <div>
        {this.renderModal()}
        <CancelAlertPopup
          show={this.state.showCancelPopup}
          alertMessage='Are you sure you want to cancel ?'
          handleYesBtnClick={this.handleClear}
          handleNoBtnClick={this.handleCancel}
        />
      </div>
    );
  }
}

export default AddCategory;
