import { GET, POST } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/merchantPayable/${endpoint}`;
};

export const getAllPayments = (token) => {
  const endpoint = getEndpointWithPrefix('getAllPayables');
  return GET(endpoint, token).then((response) => {
    return response;
  });
};

export const getPayablesByMerchantId = (request, token) => {
  const endpoint = getEndpointWithPrefix('getMerchantPayables');
  return POST(endpoint, request, token).then((response) => {
    return response;
  });
};

export const filterMerchantPayables = (request, token) => {
  const endpoint = getEndpointWithPrefix('filterMerchantPayables');
  return POST(endpoint, request, token).then((response) => {
    return response;
  });
};

export const searchMerchantPayableById = (request, token) => {
  const endpoint = getEndpointWithPrefix('getMerchantPayableById');
  return POST(endpoint, request, token).then((response) => {
    return response;
  });
};

export const addPayment = (request, token) => {
  const endpoint = getEndpointWithPrefix('addMerchantPayable');
  return POST(endpoint, request, token).then((response) => {
    return response;
  });
};

export const getDetailsForAddPayments = (token) => {
  const endpoint = getEndpointWithPrefix('getMechantIds');
  return GET(endpoint, token).then((response) => {
    return response;
  });
};

export const getAllMerchantpayables = (page, token) => {
  const endpoint = getEndpointWithPrefix(`getAllMerchantPayables/${page}`);
  return GET(endpoint, token).then((response) => {
    return response;
  });
};

export const getAllMerchantPayable = (token) => {
  const endpoint = getEndpointWithPrefix(`get-all-merchant-payable`);
  return GET(endpoint, token);
};

export const getAllMerchantPayableByDate = (token, data) => {
  const endpoint = getEndpointWithPrefix(`get-all-merchant-payable-by-date`);
  return POST(endpoint, data, token);
};
