import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

const AuthorizedComponent = (Components, acceptUserRole) => {
  const NewComponents = (props) => {
    if (acceptUserRole.includes(props.role)) {
      return <Components />;
    } else {
      return <Redirect to='/' />;
    }
  };
  const mapStateToProps = (state) => {
    return {
      role: state.auth.auth.details.role,
    };
  };
  return connect(mapStateToProps)(NewComponents);
};
export default AuthorizedComponent;
