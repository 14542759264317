import AuthorizedComponent from '../components/hoc/AuthorizedComponent';
import {
  AddOffer,
  AddTerminal,
  ContactUs,
  Dashboard,
  ViewEarnings,
  ViewOffers,
  ViewPayments,
  ViewTerminals,
  AddPayment,
  AddMerchant,
  Home,
  ViewMerchants,
  ViewPurchases,
} from '../screens';
import UserDetails from '../screens/userDetails/userDetails.screen';

export const acceptOfferRole = ['ROLE_THYAGA_ADMINISTRATOR'];
const acceptTerminalsRole = ['ROLE_THYAGA_ADMINISTRATOR'];
const acceptEarningsRole = [
  'ROLE_MERCHANT_ADMINISTRATOR',
  'ROLE_SUB_MERCHANT_ADMINISTRATOR',
];
const acceptPaymentsRole = ['ROLE_MERCHANT_ADMINISTRATOR'];
const acceptAddMerchantRole = ['ROLE_THYAGA_ADMINISTRATOR'];
const acceptAddPaymentRole = [
  'ROLE_THYAGA_ACCOUNTANT',
  'ROLE_THYAGA_ADMINISTRATOR',
];

const acceptViewPurchases = ['ROLE_THYAGA_ADMINISTRATOR'];
const acceptUserDetails = ['ROLE_THYAGA_ADMINISTRATOR'];

const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home,
  },
  {
    name: 'User Details',
    path: '/user-details',
    acceptUserRole: acceptUserDetails,
    component: AuthorizedComponent(UserDetails, acceptUserDetails),
  },
  {
    name: 'Offers',
    path: '/offers',
    acceptUserRole: acceptOfferRole,
    children: [
      {
        name: 'View Offers',
        path: '/',
        component: AuthorizedComponent(ViewOffers, acceptOfferRole),
      },
      {
        name: 'Add Offer',
        path: '/add-offer',
        component: AuthorizedComponent(AddOffer, acceptOfferRole),
      },
    ],
  },
  {
    name: 'Terminals',
    path: '/terminals',
    acceptUserRole: acceptTerminalsRole,
    children: [
      {
        name: 'View Terminals',
        path: '',
        component: AuthorizedComponent(ViewTerminals, acceptTerminalsRole),
      },
      {
        name: 'Add Terminal',
        path: '/add-terminal',
        component: AuthorizedComponent(AddTerminal, acceptTerminalsRole),
      },
    ],
  },
  {
    name: 'Earnings',
    path: '/earnings',
    acceptUserRole: acceptEarningsRole,
    children: [
      {
        name: 'View Redemptions',
        path: '',
        component: AuthorizedComponent(ViewEarnings, acceptEarningsRole),
      },
    ],
  },
  {
    name: 'Payments',
    path: '/payments',
    acceptUserRole: acceptPaymentsRole,
    children: [
      {
        name: 'View Payments',
        path: '',
        component: AuthorizedComponent(ViewPayments, acceptPaymentsRole),
      },
    ],
  },
  {
    name: 'Payments',
    path: '/payments',
    acceptUserRole: acceptAddPaymentRole,
    children: [
      {
        name: 'View Payments',
        path: '/payments',
        component: AuthorizedComponent(ViewPayments, acceptAddPaymentRole),
      },
      {
        name: 'Add Payment',
        path: '/add-payment',
        component: AuthorizedComponent(AddPayment, acceptAddPaymentRole),
      },
    ],
  },
  {
    name: 'Merchants',
    path: '/merchants',
    acceptUserRole: acceptAddMerchantRole,
    children: [
      {
        name: 'View Merchants',
        path: '/view-Merchants',
        component: AuthorizedComponent(ViewMerchants, acceptAddMerchantRole),
      },
      {
        name: 'Add Merchant',
        path: '/add-Merchant',
        component: AuthorizedComponent(AddMerchant, acceptAddMerchantRole),
      },
    ],
  },
  {
    name: 'Purchases',
    path: '/purchases',
    acceptUserRole: acceptViewPurchases,
    children: [
      {
        name: 'View Purchases',
        path: '/view-Purchases',
        component: AuthorizedComponent(ViewPurchases, acceptViewPurchases),
      },
    ],
  },
  {
    name: 'Contact Us',
    path: '/contact-us',
    component: ContactUs,
  },
];

export default routes;
