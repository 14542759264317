import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Loading, PageHeader, EarningItem, OfferItem } from '../../components';
import { getDashboard } from '../../api/merchant';
import useComponentState from '../../utils/useComponentState';
import { acceptOfferRole } from './../../routes/index';

import styles from './Dashboard.module.scss';
import { sortBydate } from '../../components/hoc/SortField';

import { authSuccess } from '../../actions/auth';
import { storeDashboardDetails } from '../../actions/dashboard';

const Dashboard = (props) => {
  const [state, setState] = useComponentState({
    dashboard: null,
    dashboardLoading: true,
    dashboardError: null,
    merchantList: [],
    selectedMerchant: null,
    voucherCountHelpText: false,
  });

  const {
    auth: { token },
  } = props;
  const currentUser = props.user.currentUser;
  const {
    dashboard,
    dashboardLoading,
    dashboardError,
    voucherCountHelpText,
  } = state;

  useEffect(() => {
    handleGetDashboardFromAPI();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setState({
      dashboard: props.dashboard.details,
    });
    //eslint-disable-next-line
  }, [props.dashboard.details]);

  const handleGetDashboardFromAPI = async () => {
    try {
      setState({ dashboardLoading: true, dashboardError: null });
      const response = await getDashboard(currentUser.id, token);

      setState({ dashboardLoading: false, dashboard: { ...response } });
      props.storeDashboardDetails(response);
    } catch (err) {
      setState({ dashboardLoading: false, dashboardError: err.message });
      handleErrorCodes(err);
    }
  };

  const handleOnEarningsSeeMoreClick = () => {
    props.history.push('/earnings');
  };

  const handleOnOffersSeeMoreClick = () => {
    props.history.push('/offers');
  };

  const renderLoading = () => {
    return <Loading text='Loading Dashboard' />;
  };

  const renderError = () => {
    return <div>{dashboardError}</div>;
  };

  const handleErrorCodes = (error) => {
    switch (error.message) {
      case 'User is not a merchant.':
        handleSetError('User is not a merchant');
        break;
      case 'Not Found':
        handleSetError('Invalid request. Please try again');
        break;
      default:
        handleSetError(error.message);
    }
  };

  const handleSetError = (err) => {
    setState({ dashboardLoading: false, dashboardError: err });
  };

  const renderDashboard = () => {
    const {
      dailyEarnings,
      monthlyEarnings,
      numberOfCustomers,
      numberOfVouchers,
      voucherCount,
      earnings: { earnings },
      offers: { offers },
    } = dashboard;
    sortBydate(earnings);

    const earningsToRender = earnings.map((earning) => {
      return <EarningItem earning={earning} />;
    });
    const offersToRender = offers.map((offer) => {
      return <OfferItem offer={offer} />;
    });

    const handleOnVoucherCountHelpTextClick = () => {
      setState({
        voucherCountHelpText: true,
      });
    };

    return (
      <div className={styles.dashboard__div}>
        <div className={styles.summary__root}>
          <div className={styles.summary}>
            <span className={styles.summary__header}>
              Monthly Earnings (LKR)
            </span>
            <span className={styles.summary__value}>
              {monthlyEarnings === '.00' ? '0' : monthlyEarnings}
            </span>
          </div>
          <div className={styles.summary}>
            <span className={styles.summary__header}>
              No. of Vouchers Redeemed
            </span>
            <span className={styles.summary__value}>{numberOfVouchers}</span>
          </div>
          <div className={styles.summary}>
            <span className={styles.summary__header}>No. of Customers</span>
            <span className={styles.summary__value}>{numberOfCustomers}</span>
          </div>
          <div className={styles.summary}>
            <span className={styles.summary__header}>
              Assigned Voucher Count
            </span>
            <span className={styles.summary__value}>{voucherCount}</span>
            <span
              className={styles.summary__hint_but}
              onClick={handleOnVoucherCountHelpTextClick}
            >
              Disclaimer
            </span>
            {voucherCountHelpText && (
              <span className={styles.summary__hint_text}>
                Kindly note that all assigned vouchers may not redeem at your
                store, as Thyaga is a multi-store voucher
              </span>
            )}
          </div>
        </div>
        <div className={styles.tables__div}>
          <div className={styles.earnings__div}>
            <PageHeader text='Earnings' />
            <div className={styles.table__header}>
              <span>Date (IST)</span>
              <span>Redemption Id</span>
              <span>Reference Code</span>
              <span>Status</span>
              <span>Earned Amount (LKR)</span>
            </div>
            {earnings.length === 0 ? (
              <div>
                <p className={styles.redemption_error}>No redemptions</p>
              </div>
            ) : (
              earningsToRender
            )}
            {earnings.length === 10 && (
              <div className={styles.table__footer}>
                <span onClick={handleOnEarningsSeeMoreClick}>See More</span>
              </div>
            )}
          </div>
          {acceptOfferRole.includes(props.auth.details.role) && (
            <div className={styles.offers__div}>
              <PageHeader text='Offers' />
              <div className={styles.table__header}>
                <span>ID</span>
                <span>Name</span>
                <span>Status</span>
              </div>
              {offersToRender}
              <div className={styles.table__footer}>
                <span onClick={handleOnOffersSeeMoreClick}>See More</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.root__div}>
      {dashboardLoading
        ? renderLoading()
        : dashboardError
        ? renderError()
        : renderDashboard()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    dashboard: state.dashboard,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginSuccess: (data) => {
      dispatch(authSuccess(data));
    },
    storeDashboardDetails: (data) => {
      dispatch(storeDashboardDetails(data));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
