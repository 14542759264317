import React from 'react';
import DatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import useComponentState from '../../utils/useComponentState';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './DateFilter.module.scss';

const DateFilter = ({
  label,
  onBtnPress,
  onClear,
  onStartDateChange,
  onEndDateChange,
}) => {
  const [state, setState] = useComponentState({
    startDate: null,
    endDate: null,
    isApiCalled: false,
    validated: false,
    show: false,
    showStart: false,
    showEnd: false,
  });

  const handleClick = (tag) => {
    if (tag === 'start') {
      setState({
        showStart: !state.showStart,
        showEnd: false,
      });
    } else {
      setState({
        showEnd: !state.showEnd,
        showStart: false,
      });
    }
  };

  const handleStartDateChange = (date) => {
    setState({
      showStart: false,
      showEnd: false,
      startDate: date,
      isApiCalled: false,
    });
    onStartDateChange(date);
  };

  const handleEndDateSelect = (date) => {
    setState({
      showStart: false,
      showEnd: false,
      endDate: date,
      isApiCalled: false,
    });
    onEndDateChange(date);
  };

  const handleOnSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (!state.isApiCalled) {
      setState({
        validated: true,
        isApiCalled: true,
      });

      const start = state.startDate;
      const end = state.endDate;

      onBtnPress(start, end);
    } else {
      setState({
        isApiCalled: false,
        validated: true,
        startDate: null,
        endDate: null,
      });
      onClear();
    }
  };

  const renderStartDatePicker = (placeholder) => {
    return (
      <DatePicker
        className={styles.date_picker__input}
        placeholderText={placeholder}
        selected={state.startDate}
        onChange={handleStartDateChange}
        customInput={
          <StartingDateControl
            placeholder={placeholder}
            value={state.startDate}
          />
        }
        dateFormat='dd/MM/yyyy'
        open={state.showStart}
      />
    );
  };

  const renderEndDatePicker = (placeholder) => {
    return (
      <DatePicker
        className={styles.date_picker__input}
        placeholderText={placeholder}
        selected={state.endDate}
        onChange={handleEndDateSelect}
        customInput={
          <EndingDateControl placeholder={placeholder} value={state.endDate} />
        }
        dateFormat='dd/MM/yyyy'
        open={state.showEnd}
      />
    );
  };

  const EndingDateControl = React.forwardRef(({ placeholder, value }, ref) => (
    <Form.Control
      placeholder={placeholder}
      aria-label={placeholder}
      value={value}
      required
      readOnly
      onClick={() => handleClick('end')}
      className='bg-white'
    />
  ));

  const StartingDateControl = React.forwardRef(
    ({ placeholder, value }, ref) => (
      <Form.Control
        placeholder={placeholder}
        aria-label={placeholder}
        value={value}
        required
        readOnly
        onClick={() => handleClick('start')}
        className='bg-white'
      />
    )
  );

  return (
    <div className={styles.dateFilter_root}>
      <Form noValidate validated={state.validated} onSubmit={handleOnSubmit}>
        <InputGroup className='mb-3'>
          <InputGroup.Prepend>
            <InputGroup.Text>{label}</InputGroup.Text>
          </InputGroup.Prepend>
          {renderStartDatePicker('Start Date')}
          {renderEndDatePicker('End Date')}
          <InputGroup.Append>
            <Button variant='primary' type='submit' className={styles.btn}>
              {state.isApiCalled ? 'Clear' : 'Filter'}
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    </div>
  );
};

export default DateFilter;
