import { POST } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/purchases/${endpoint}`;
};

export const getPurchases = (request, token) => {
  const endpoint = getEndpointWithPrefix('getPurchases');
  return POST(endpoint, request, token);
};

export const searchPurchases = (request, token) => {
  const endpoint = getEndpointWithPrefix('searchById');
  return POST(endpoint, request, token);
};

export const filterPurchases = (request, token) => {
  const endpoint = getEndpointWithPrefix('filterByDate');
  return POST(endpoint, request, token);
};
