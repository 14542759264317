import React from 'react';
import { useHistory } from 'react-router-dom';

import useComponentState from './../../utils/useComponentState';
import ForgotPasswordDiv from '../../components/forgotPassword/ForgotPassword.component';
import VerifyKey from '../../components/forgotPassword/VerifyKey.components';
import ResetPassword from '../../components/forgotPassword/ResetPassword.component';
import Loading from './../../components/loading/Loading.component';
import {
  forgetPassword,
  verifyResetPasswordKey,
  resetForgotPassword,
} from './../../api/forgotPassword';

import styles from './ForgotPassword.module.scss';
import logo from '../../styles/images/login_logo_high_res.png';

const ForgotPassword = (props) => {
  const history = useHistory();
  const [state, setState] = useComponentState({
    cState: 'forgotPassword',
    email: '',
    key: '',
    newPassword: '',
    error: null,
  });
  const { cState, email, key, newPassword, error } = state;

  const handleOnResendSubmit = async () => {
    try {
      setState({ cState: 'forgotPasswordLoading', error: null });
      await forgetPassword(email);

      setState({ cState: 'verfyKey' });
    } catch (e) {
      setState({
        cState: 'verfyKey',
        error: 'Oops, something went wrong. Please try again',
      });
    }
  };

  const handleEmailOnSubmit = async () => {
    try {
      setState({ cState: 'forgotPasswordLoading', error: null });
      await forgetPassword(email);

      setState({ cState: 'verfyKey' });
    } catch (e) {
      if (e.message === 'USER_NOT_FOUND') {
        setState({
          cState: 'forgotPassword',
          error: 'Entered email address is not registered with us',
        });
      } else {
        setState({
          cState: 'forgotPassword',
          error: 'Oops, something went wrong. Please try again',
        });
      }
    }
  };
  const handleEmailOnChange = (data) => {
    setState({ email: data.target.value, error: null });
  };

  const handlekeyOnSubmit = async () => {
    try {
      setState({ cState: 'verfyKeyLoading', error: null });
      await verifyResetPasswordKey(email, key);

      setState({ cState: 'setPassword' });
    } catch (e) {
      if (e.message === 'KEY_MISMATCH') {
        setState({
          cState: 'verfyKey',
          error: 'Entered reset key is incorrect. Please try again!',
        });
      } else {
        setState({
          cState: 'verfyKey',
          error: 'Oops, something went wrong. Please try again',
        });
      }
    }
  };
  const handleKeyOnChange = (data) => {
    setState({ key: data.target.value, error: null });
  };

  const handlePasswordOnSubmit = async () => {
    try {
      setState({ cState: 'setPasswordLoading', error: null });
      await resetForgotPassword(email, key, newPassword);

      history.push('/');
    } catch (e) {
      setState({
        cState: 'setPassword',
        error: 'Oops, something went wrong. Please try again',
      });
    }
  };
  const handlePasswordOnChange = (data) => {
    setState({ newPassword: data, error: null });
  };

  const renderCurrentDiv = () => {
    if (cState === 'forgotPassword') {
      return (
        <div>
          <ForgotPasswordDiv
            onSubmit={handleEmailOnSubmit}
            onChange={handleEmailOnChange}
            value={email}
            handleGoBack={handleGoBack}
            error={error}
          />
        </div>
      );
    }
    if (cState === 'verfyKey') {
      return (
        <div>
          <VerifyKey
            onSubmit={handlekeyOnSubmit}
            onChange={handleKeyOnChange}
            onResendButtonPress={handleOnResendSubmit}
            value={key}
            handleGoBack={handleGoBack}
            error={error}
            email={email}
          />
        </div>
      );
    }
    if (cState === 'setPassword') {
      return (
        <div>
          <ResetPassword
            onSubmit={handlePasswordOnSubmit}
            onChange={handlePasswordOnChange}
            value={newPassword}
            handleGoBack={handleGoBack}
            error={error}
          />
        </div>
      );
    }
    if (cState === 'forgotPasswordLoading') {
      return <Loading text='Sending reset code' />;
    }
    if (cState === 'verfyKeyLoading') {
      return <Loading text='verify key' />;
    }
    if (cState === 'setPasswordLoading') {
      return <Loading text='Setting new password' />;
    }
    return;
  };

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <div className={styles.root__div}>
      <div className={styles.child__div}>
        <div className={styles.logo__div}>
          <img src={logo} className={styles.logo__img} alt='logo' />
        </div>
        <div>
          <div>
            <h2>Forgot password?</h2>
          </div>
          {renderCurrentDiv()}
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
