import React from 'react';
import { Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { AppInput } from '../../components';

import styles from './ForgotPassword.module.scss';

const ForgotPassword = (props) => {
  const { onSubmit, onChange, value, error, handleGoBack } = props;
  return (
    <div className={styles.root}>
      <div className={styles.forgotpw__sub_title}>
        Don't worry. Tell us the email address you registered with Thyāga
      </div>
      <Formik
        initialValues={{
          email: value,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required('Email address is required')
            .email('Please enter a valid email address'),
        })}
        onSubmit={onSubmit}
      >
        <Form onChange={onChange} className={styles.root_form}>
          <AppInput
            className={styles.form_input}
            name='email'
            type='text'
            placeholder='Email Address'
            maxLength='320'
          />
          {error && <p className={styles.forgetPassword_error}>{error}</p>}
          <div className={styles.button__group}>
            <Button className={styles.form_button} variant='dark' type='submit'>
              SEND RESET CODE
            </Button>
          </div>
        </Form>
      </Formik>
      <div className={styles.navigate_back_div}>
        <Button variant='link' onClick={handleGoBack}>
          SIGN IN
        </Button>
      </div>
    </div>
  );
};
export default ForgotPassword;
