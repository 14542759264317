import React from 'react';
import { MoonLoader } from 'react-spinners';

import styles from './Loading.module.scss';

const Loading = (props) => {
  const { text } = props;

  return (
    <div className={styles.root__div}>
      <MoonLoader size={30} color={'#3a195a'} />
      <span className={styles.loading__text}>{text}</span>
    </div>
  );
};

export default Loading;
