import React from 'react';

import styles from './AppButton.module.scss';

const AppButton = (props) => {
  const { containerStyle, loading, onClick, type, text } = props;

  return (
    <div className={styles.button__container} style={containerStyle}>
      <button
        className={styles.button}
        disabled={loading}
        onClick={onClick}
        type={type}
      >
        {text}
      </button>
    </div>
  );
};

AppButton.defaultProps = {
  onClick: () => {},
  type: 'button',
  containerStyle: {},
};

export default AppButton;
