import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Form from 'react-bootstrap/Form';

import { initiateLogout } from '../../actions/auth';
import routes from '../../routes';
import { getDashboard } from '../../api/merchant';

import useComponentState from '../../utils/useComponentState';

import { authSuccess } from '../../actions/auth';
import { storeDashboardDetails } from '../../actions/dashboard';
import { setCurrentUser } from '../../actions/user';

import logo from '../../styles/images/logo_high_res.png';
import styles from './SideBar.module.scss';

const SideBar = (props) => {
  const [state, setState] = useComponentState({
    deshboardFetchError: null,
  });

  const { details, token } = props.auth.auth;
  const { users, currentUser, role } = props.user;

  const handleGetDashboardFromAPI = async (value) => {
    try {
      const response = await getDashboard(value, token);
      props.storeDashboardDetails(response);
      handleSetCurrentUser(value);
    } catch (err) {
      setState({ deshboardFetchError: err.message });
    }
  };

  const handleSetCurrentUser = (id) => {
    const currentUser = users.filter((item) => item.merchant.id === id);
    props.setCurrentUser(currentUser[0].merchant);
  };

  const handleMerchantOnChange = (e) => {
    const value = e.target.value;
    setState({ merchant: value });
    handleGetDashboardFromAPI(value);
  };

  const drawerStyles = props.isOpen
    ? [styles.side__drawer, styles.open].join(' ')
    : styles.side__drawer;

  const handleOnClick = (path) => {
    props.history.push(path);
  };

  const handleOnLogoutClick = () => {
    props.logout();
  };

  const {
    location: { pathname },
  } = props;

  const renderDetailsBlock = () => {
    switch (role) {
      case 'ROLE_MERCHANT_ADMINISTRATOR':
        return renderMerchantDetailBlock();
      case 'ROLE_SUB_MERCHANT_ADMINISTRATOR':
        return renderSubMerchantDetailsBlock();
      default:
        return <div className={styles.empty_div} />;
    }
  };

  const renderMerchantDropDown = () => {
    return (
      <Form.Group controlId='exampleForm.ControlSelect1'>
        <Form.Label>Select Merchant</Form.Label>
        <Form.Control
          as='select'
          onClick={handleMerchantOnChange}
          name='merchant'
        >
          {users.map((item, index) => {
            return (
              <option value={item.merchant.id} key={index}>
                {item.merchant.name}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    );
  };

  const merchantRoutes = () => {
    const userRole = props.auth.auth.details.role;
    const itemsToRender = routes
      .filter(
        ({ acceptUserRole }) =>
          acceptUserRole === undefined ||
          (acceptUserRole && acceptUserRole.includes(userRole))
      )
      .map((route) => {
        const { children, path, name } = route;
        const hasChildren = children && children.length > 0;
        const parentStyle =
          path === pathname
            ? [styles.title, styles.clickable, styles.selected].join(' ')
            : [styles.title, styles.clickable].join(' ');

        return hasChildren ? (
          <div className={styles.title}>
            <span>{name}</span>
            <div className={styles.child__list}>
              {children.map((child) => {
                const childPath = `${path}${child.path}`;
                const style =
                  childPath === pathname
                    ? [styles.item, styles.clickable, styles.selected].join(' ')
                    : [styles.item, styles.clickable].join(' ');
                return (
                  <div
                    className={style}
                    onClick={() => {
                      handleOnClick(childPath);
                    }}
                  >
                    {child.name}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div
            className={parentStyle}
            onClick={() => {
              handleOnClick(path);
            }}
          >
            <span>{name}</span>
          </div>
        );
      });
    return (
      <div className={styles.list}>
        {itemsToRender}
        <div
          className={[styles.title, styles.clickable].join(' ')}
          onClick={handleOnLogoutClick}
        >
          <span>Log out</span>
        </div>
      </div>
    );
  };

  const navigationRoutes = merchantRoutes();

  const renderSubMerchantDetailsBlock = () => {
    return (
      <div className={styles.user_details__div}>
        <div className={styles.user_details_p_block}>
          <p className={styles.user_details__p}>{currentUser.email}</p>
          <p className={styles.user_details__p}>{currentUser.location}</p>
        </div>
      </div>
    );
  };

  const renderMerchantDetailBlock = () => {
    return (
      <div className={styles.user_details__div}>
        <div className={styles.user_details__img_div}>
          <img
            className={styles.user_details__img}
            src={currentUser.imgUrl}
            alt='user_image'
          />
        </div>
        <div className={styles.user_details_p_block}>
          <p className={styles.user_details__p}>{details.email}</p>
        </div>
      </div>
    );
  };

  return (
    <div className={drawerStyles}>
      <div className={styles.flex__div} />
      <img src={logo} className={styles.logo__img} alt='Logo' />
      <div className={styles.merchant_dropDown}>
        {role === 'ROLE_MERCHANT_ADMINISTRATOR' && renderMerchantDropDown()}
      </div>
      {renderDetailsBlock()}
      {navigationRoutes}
      <div className={styles.flex__div} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(initiateLogout());
    },
    loginSuccess: (data) => {
      dispatch(authSuccess(data));
    },
    storeDashboardDetails: (data) => {
      dispatch(storeDashboardDetails(data));
    },
    setCurrentUser: (data) => {
      dispatch(setCurrentUser(data));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideBar)
);
