import React from 'react';
import moment from 'moment';

import styles from './OfferItem.module.scss';

const OfferItem = (props) => {
  const {
    offer: { code, title, merchant, endDate },
    onClick,
    offer,
  } = props;

  const handleOnOfferSelect = () => {
    onClick && onClick(offer);
  };

  const rootStyle = onClick
    ? [styles.root__div, styles.clickable].join(' ')
    : styles.root__div;

  return (
    <div className={rootStyle} onClick={handleOnOfferSelect}>
      <span>{code}</span>
      <span>{title}</span>
      <span>{merchant ? merchant.name : 'N/A'}</span>
      <span>{moment(endDate).format('DD-MM-YYYY hh.mm.ss')}</span>
      <span>Active</span>
    </div>
  );
};

OfferItem.defaultProps = {
  onClick: null,
};

export default OfferItem;
