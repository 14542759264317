import createReducer from '../utils/createReducer';

import { STORE_DASHBOARD_DETAILS } from '../actions/dashboard';

const initialState = {
  details: null,
};

const storeDetails = (
  state = initialState,
  { type, payload: { ...payload } }
) => {
  return {
    ...state,
    details: {
      ...payload,
    },
  };
};

export default createReducer(initialState, {
  [STORE_DASHBOARD_DETAILS]: storeDetails,
});
