import createReducer from '../utils/createReducer';

import {
  AUTH_SUCCESS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_SUCCESS,
  INITIATE_LOGOUT,
  INITIATE_LOGOUT_SUCCESS,
} from '../actions/auth';

const initialState = {
  auth: null,
  userDetailsLoading: true,
  userDetailsError: null,
  logoutLoading: false,
};

const authSuccess = (
  state = initialState,
  { type, payload: { ...payload } }
) => {
  return {
    ...state,
    auth: {
      ...payload,
    },
  };
};

const getUserDetails = (state = initialState, { payload }) => {
  return {
    ...state,
  };
};

const getUserDetailsSuccess = (
  state = initialState,
  { payload: { expirationInMilliseconds, ...payload } }
) => {
  return {
    ...state,
    auth: {
      ...payload,
    },
    userDetailsLoading: false,
  };
};

const getUserDetailsFailure = (state = initialState, { payload }) => {
  return {
    ...state,
    auth: null,
    userDetailsLoading: false,
  };
};

const initiateLogout = (state = initialState, payload) => {
  return {
    ...state,
    logoutLoading: true,
  };
};

const initiateLogoutSuccess = (state, initialState, payload) => {
  return {
    ...state,
    auth: null,
    logoutLoading: false,
  };
};

export default createReducer(initialState, {
  [AUTH_SUCCESS]: authSuccess,
  [GET_USER_DETAILS]: getUserDetails,
  [GET_USER_DETAILS_SUCCESS]: getUserDetailsSuccess,
  [GET_USER_DETAILS_FAILURE]: getUserDetailsFailure,
  [INITIATE_LOGOUT]: initiateLogout,
  [INITIATE_LOGOUT_SUCCESS]: initiateLogoutSuccess,
});
