import React from 'react';

import Table from 'react-bootstrap/Table';

import Search from '../Search/Search.component';
import Pagination from '../pagination/Pagination.component';

import styles from './MerchantSelector.module.scss';

const MerchantSelector = ({
  data,
  merchantId,
  merchantPageCount,
  handleMerchantSearchById,
  handleMerchantSearchByName,
  handleMerchantPagination,
  handleMerchantIdOnChange,
  handleMerchantsClear,
  searchedMerchantName,
  searchedMerchantId,
  paginateList,
}) => {
  const renderPaginate = () => {
    return (
      paginateList.length > 10 && (
        <Pagination
          pageCount={merchantPageCount}
          onChange={handleMerchantPagination}
          currentPage={0}
        />
      )
    );
  };

  const renderNotAvailable = () => {
    return (
      <div className='text-center'>
        {searchedMerchantName.length > 0 ? (
          <span>{`No merchant found as ${searchedMerchantName}`}</span>
        ) : (
          <span>{`No merchant found with ID ${searchedMerchantId}`}</span>
        )}
      </div>
    );
  };

  const renderHead = () => {
    return (
      <th className={styles.table_filter_search} colSpan={3}>
        <div className={styles.table_search}>
          <Search
            placeholder='Merchant Name'
            onBtnPress={handleMerchantSearchByName}
            max={50}
            onClearPress={handleMerchantsClear}
          />
          <div className={styles.separator} />
          <Search
            placeholder='Merchant ID'
            onBtnPress={handleMerchantSearchById}
            max={10}
            onClearPress={handleMerchantsClear}
          />
        </div>
      </th>
    );
  };

  return (
    <div className={styles.merchantDrawer_div}>
      <div>
        <Table>
          <thead>{renderHead()}</thead>
          <thead>
            <th></th>
            <th>Name</th>
            <th>ID</th>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((option) => (
                <tr className={styles.data}>
                  <td>
                    <input
                      type='radio'
                      name='option'
                      onChange={() => {
                        handleMerchantIdOnChange(option.name, option.id);
                      }}
                      value={option.id}
                      checked={
                        merchantId && merchantId === option.id ? true : false
                      }
                    />
                  </td>
                  <td>
                    <span value={option} id={option}>
                      {option.name}
                    </span>
                  </td>
                  <td>
                    <span value={option} id={option}>
                      {option.id}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td></td>
                <td>{renderNotAvailable()}</td>
              </tr>
            )}
          </tbody>
        </Table>
        {renderPaginate()}
      </div>
    </div>
  );
};

export default MerchantSelector;
