const offerTypes = [
  {
    label: 'Discount',
    value: 'OFFER_DISCOUNT',
  },
  {
    label: 'Flat Rate',
    value: 'OFFER_FLAT_RATE',
  },
];

export default offerTypes;
