export const setUsers = (data) => {
  return {
    type: SET_USERS,
    payload: data,
  };
};

export const setCurrentUser = (data) => {
  return {
    type: SET_CURRENT_USER,
    payload: data,
  };
};

export const setCurrentUserRole = (data) => {
  return {
    type: SET_CURRENT_USER_ROLE,
    payload: data,
  };
};

export const setUserDetails = (payload) => ({
  type: SET_USER_DETAILS,
  payload,
});

export const SET_USERS = 'SET_USERS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_ROLE = 'SET_CURRENT_USER_ROLE';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
