import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';

import { getDetailsForAddPayments, addPayment } from '../../api/Payments';
import {
  getEarningsBetweenDateForPayments,
  getEarningsByRid,
  getNotPaidRedemptionsByMerchant,
} from '../../api/earning';
import { Pagination, Loading } from '../../components';
import Search from '../../components/Search/Search.component';
import DateFilter from '../../components/DateFilter/DateFilter.component';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './addPayment.module.scss';

class AddPayment extends Component {
  state = {
    isApiCalled: false,
    loading: true,
    redemptionLoading: true,
    merchantName: '',
    merchantId: null,
    redemptionId: null,
    merchantIds: [],
    slicedMerchantIds: [],
    redemptionIds: [],
    redemptionOptions: [],
    mainTotal: 1,
    total: 1,
    mainCurrent: 0,
    current: 0,
    redemptionsFetched: false,
    redemptionError: '',
    isSuccess: false,
    addError: null,
    detailsError: '',
    isFiltered: true,
    isSearched: true,
    searching: false,
    filtering: false,
    search_filter_redemptions: [],
    isMerchantSearched: false,
    sliceStart: 0,
    sliceEnd: 10,
    merchantPages: 0,
    error: null,
    totalAmount: 0,
    searchedMerchant: null,
    searchedRedemption: null,
    showCancelModal: false,
    disableCancel: true,
    merchantpaginationIds: [],
    startDate: null,
    endDate: null,
    searchRedemptionId: '',
    paymentDate: null,
    searchMerchantName: '',
    searchMerchantId: '',
  };

  componentWillMount() {
    getDetailsForAddPayments(this.props.auth.token)
      .then((response) => {
        const data = this.sortMerchantList(response);
        this.setState({
          merchantIds: data,
          loading: false,
          merchantName: data[0].name,
          merchantId: data[0].id,
          slicedMerchantIds: response.slice(
            this.state.sliceStart,
            this.state.sliceEnd
          ),
          merchantPages: this.getMerchantPage(response),
          merchantpaginationIds: data,
        });
        this.fetchRedemptionId(data[0].id, 0);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          redemptionsFetched: true,
          detailsError: 'Merchant Ids fetch failed',
        });
        this.handleErrorCodes(err);
      });
  }

  getMerchantPage = (data) => {
    var merchantPage = 0;
    var mod = data.length % 10;
    if (mod > 0) {
      merchantPage = data.length / 10 - mod / 10 + 1;
    } else {
      merchantPage = data.length / 10;
    }
    return merchantPage;
  };

  sortMerchantList = (data) => {
    return data.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
  };

  fetchRedemptionId = (id, page) => {
    const request = {
      merchantId: id,
      page: page,
    };
    this.setState({ redemptionLoading: true });
    getNotPaidRedemptionsByMerchant(request, this.props.auth.token)
      .then((response) => {
        this.setState({
          redemptionIds: response.earnings,
          total: response.total,
          current: response.current,
          redemptionsFetched: true,
          mainCurrent: response.current,
          mainTotal: response.total,
          redemptionLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          redemptionsFetched: true,
          redemptionLoading: false,
        });
        this.handleErrorCodes(err);
      });
  };

  handleSearch = async (input, page) => {
    if (input) {
      const item = this.state.redemptionIds.filter((item) => {
        var itemData = item.id;
        return itemData.indexOf(input) > -1;
      });
      if (item.length !== 0 && input.length === 10) {
        this.setState({
          isSearched: true,
          searching: true,
          filtering: false,
          search_filter_redemptions: item,
          searchedRedemption: `found with ID ${input}`,
          total: this.getMerchantPage(item),
          current: 0,
        });
      } else {
        const merchantId = this.state.merchantId;
        const token = this.props.auth.token;
        const param = {
          page: page ? page : 0,
          redemptionid: input,
          selectIdType: 'REDEMPTION_ID_FOR_PAYMENT',
        };
        this.setState({
          isSearched: false,
          addError: null,
          searchedRedemption: `found with ID ${input}`,
        });
        try {
          const response = await getEarningsByRid(merchantId, token, param);
          this.setState({
            isSearched: true,
            searching: true,
            filtering: false,
            search_filter_redemptions: response.earnings,
            total: response.total,
            current: response.current,
          });
        } catch (error) {
          this.setState({
            isSearched: true,
            searching: true,
            filtering: false,
          });
          this.handleErrorCodes(error);
        }
      }
    }
  };

  handleFilter = (start, end, page) => {
    const merchantId = this.state.merchantId;
    const token = this.props.auth.token;
    const startx = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endx = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const param = {
      page: page ? page : 0,
      startDate: startx,
      endDate: endx,
    };

    this.setState({
      isFiltered: false,
      searching: false,
      filtering: true,
      disableCancel: false,
    });
    getEarningsBetweenDateForPayments(merchantId, token, param)
      .then((response) => {
        this.setState({
          search_filter_redemptions: response.earnings,
          total: response.total,
          current: response.current,
          isFiltered: true,
          searchedRedemption: `for ${moment(start).format(
            'DD/MM/YYYY'
          )} - ${moment(end).format('DD/MM/YYYY')}`,
        });
      })
      .catch((err) => {
        this.setState({
          isFiltered: true,
          search_filter_redemptions: [],
          searchedRedemption: `Found Between ${moment(start).format(
            'DD/MM/YYYY'
          )} - ${moment(end).format('DD/MM/YYYY')}`,
        });
        this.handleErrorCodes(err);
      });
  };

  handleOnSubmit = () => {
    this.setState({
      isApiCalled: true,
      addError: null,
      disableCancel: true,
    });
    if (this.selectedRedemptionCount() > 0) {
      const redemptions = [];
      this.state.redemptionOptions.filter((redemption) => {
        if (redemption.selected) {
          redemptions.push(redemption.redemptionId);
        }
      });
      const request = {
        merchantId: this.state.merchantId,
        redemptionIds: redemptions,
        amount: this.state.totalAmount,
        paymentDate: this.state.paymentDate,
      };
      addPayment(request, this.props.auth.token)
        .then((response) => {
          if (response.status === 'MERCHANT_PAYABLE_ADDED_SUCCESSFULLY') {
            this.setState({
              isSuccess: true,
              redemptionOptions: [],
              totalAmount: 0,
              disableCancel: false,
            });
          }
          this.setState({
            isApiCalled: false,
          });
          this.fetchRedemptionId(this.state.merchantId, 0);
        })
        .catch((err) => {
          this.setState({
            isApiCalled: false,
            addError: 'Add payment unsuccessful',
            disableCancel: false,
          });
          this.handleErrorCodes(err);
        });
    } else {
      this.setState({
        isApiCalled: false,
        addError: 'Select atleast one redemption to continue',
        disableCancel: false,
      });
    }
  };

  handleErrorCodes = (error) => {
    switch (error.message) {
      case 'MERCHANT_NOT_FOUND':
        this.handleSetError('Invalid request. Please try again');
        break;
      case 'REDEMPTION_NOT_FOUND':
        this.handleSetError('Invalid request. Please try again');
        break;
      case 'MERCHANT_MISMATCH':
        this.handleSetError(
          "Redemption's merchant and request merchant mismatch"
        );
        break;
      default:
        this.handleSetError(error.message);
    }
  };

  handleSetError = (err) => {
    this.setState({
      error: err,
    });
  };

  handleMerchantIdOnChange = (name, id) => {
    this.setState({
      merchantId: id,
      merchantName: name,
      redemptionsFetched: false,
      isSuccess: false,
      searching: false,
      filtering: false,
      disableCancel: false,
      addError: '',
      totalAmount: 0,
      redemptionOptions: [],
    });
    this.fetchRedemptionId(id, 0);
  };

  handleRedemptionIdOnChange = (id, amount) => {
    var options = [];
    var selection;
    const selectedOptions = this.state.redemptionOptions;
    if (selectedOptions.length === 0) {
      selection = { redemptionId: id, selected: true };
      options.push(selection);
      this.setState({
        totalAmount: this.state.totalAmount + amount,
      });
    } else {
      const current = selectedOptions.filter((option) => {
        if (option.redemptionId === id) {
          return option;
        }
      });

      if (current.length > 0) {
        options = selectedOptions.filter((option) => {
          if (option.redemptionId !== id) {
            return option;
          }
        });
        selection = { redemptionId: id, selected: !current[0].selected };
        options.push(selection);
        if (!current[0].selected) {
          this.setState({
            totalAmount: this.state.totalAmount + amount,
          });
        } else {
          this.setState({
            totalAmount: this.state.totalAmount - amount,
          });
        }
      } else {
        selection = { redemptionId: id, selected: true };
        options = selectedOptions;
        options.push(selection);
        this.setState({
          totalAmount: this.state.totalAmount + amount,
        });
      }
    }

    this.setState({
      redemptionOptions: options,
      redemptionError: '',
      addError: null,
      disableCancel: false,
    });
  };

  selectedRedemptionCount = () => {
    var count = 0;
    this.state.redemptionOptions.filter((option) => {
      if (option.selected) {
        count++;
      }
    });
    return count;
  };

  getRedemptionCheck = (id) => {
    var checked = false;
    if (this.state.redemptionOptions.length > 0) {
      this.state.redemptionOptions.filter((option) => {
        if (option.redemptionId === id && option.selected) {
          checked = true;
          return;
        }
      });
    }
    return checked;
  };

  handlePagination = (page) => {
    if (this.state.searching) {
      this.handleSearch(this.state.searchRedemptionId, page);
    } else if (this.state.filtering) {
      this.handleFilter(this.state.startDate, this.state.endDate, page);
    } else {
      this.fetchRedemptionId(this.state.merchantId, page);
    }
  };

  handleClear = () => {
    this.setState({
      search_filter_redemptions: [],
      isSearched: true,
      isFiltered: true,
      searching: false,
      filtering: false,
      total: this.state.mainTotal,
      current: this.state.mainCurrent,
      searchRedemptionId: '',
    });
  };

  handleMerchantSearch = (id) => {
    if (id) {
      const item = this.state.merchantIds.filter((item) => {
        return item.id.indexOf(id) > -1;
      });

      this.setState({
        slicedMerchantIds: item.slice(0, 10),
        isMerchantSearched: true,
        searchedMerchant: `found with ID ${id}`,
        merchantpaginationIds: item,
        merchantPages: this.getMerchantPage(item),
        searchMerchantName: '',
      });
    }
  };

  handleMerchantNameSearch = (name) => {
    if (name) {
      const item = this.state.merchantIds.filter((item) => {
        let itemData = item.name.toLowerCase();
        return itemData.indexOf(name.toLowerCase()) > -1;
      });

      this.setState({
        slicedMerchantIds: item.slice(0, 10),
        isMerchantSearched: true,
        searchedMerchant: `found as ${name}`,
        merchantpaginationIds: item,
        merchantPages: this.getMerchantPage(item),
        searchMerchantId: '',
      });
    }
  };

  handleMerchantPagination = (page) => {
    if (page > 0) {
      const sliceStart = page * 10;
      const sliceEnd = (page + 1) * 10;
      this.setState({
        sliceStart,
        sliceEnd,
        slicedMerchantIds: this.state.merchantpaginationIds.slice(
          sliceStart,
          sliceEnd
        ),
      });
    } else {
      const sliceStart = 0;
      const sliceEnd = 10;
      this.setState({
        sliceStart,
        sliceEnd,
        slicedMerchantIds: this.state.merchantpaginationIds.slice(
          sliceStart,
          sliceEnd
        ),
      });
    }
  };

  handleMerchantsClear = () => {
    this.setState({
      slicedMerchantIds: this.state.merchantIds.slice(0, 10),
      isMerchantSearched: false,
      searchedMerchant: null,
      merchantpaginationIds: this.state.merchantIds,
      merchantPages: this.getMerchantPage(this.state.merchantIds),
    });
  };

  handleRedemptionFilterClear = () => {
    this.setState({
      startDate: null,
      endDate: null,
      filtering: false,
      search_filter_redemptions: [],
      total: this.state.mainTotal,
    });
  };

  handlePopupState = (boolean) => {
    this.setState({
      showCancelModal: boolean,
    });
  };

  handleCancelPayment = () => {
    this.setState({
      showCancelModal: false,
      redemptionOptions: [],
      totalAmount: 0,
      addError: null,
    });
  };

  renderErrorMessage = (err) => {
    return <small className={styles.err}>{err}</small>;
  };

  renderSuccessMessage = () => {
    return <span className={styles.success}>Add payment successful</span>;
  };

  renderInputField = (placeholder, value, onChange) => {
    return (
      <Form.Control
        placeholder={placeholder}
        aria-label={placeholder}
        value={value}
        onChange={onChange}
      />
    );
  };

  renderNotAvailable = (item) => {
    return (
      <div className='text-center'>
        <span>{item}</span>
      </div>
    );
  };

  renderLoadAllBtn = (onClick) => {
    return (
      <Button variant='outline-primary' type='submit' onClick={onClick}>
        Load All
      </Button>
    );
  };

  renderRedemptions = (data) => {
    return data.map((option) => (
      <tr className={styles.data}>
        <td>
          <input
            type='checkbox'
            name='option'
            onChange={() => {
              this.handleRedemptionIdOnChange(option.id, option.earnedAmount);
            }}
            value={option.id}
            checked={this.getRedemptionCheck(option.id)}
          />
        </td>
        <td>
          <span value={option} id={option}>
            {moment(option.dateTime).format('DD/MM/YYYY hh:mm A')}
          </span>
        </td>
        <td>
          <span value={option} id={option}>
            {option.id}
          </span>
        </td>
        <td>
          <span value={option} id={option}>
            {option.earnedAmount &&
              option.earnedAmount
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </span>
        </td>
      </tr>
    ));
  };

  handleOnStartDateChange = (startDate) => {
    this.setState({ startDate: startDate });
  };
  handleOnEndDateChange = (endDate) => {
    this.setState({ endDate: endDate });
  };
  handleOnSearchRedemptionIdChange = (value) => {
    this.setState({ searchRedemptionId: value });
  };

  handleSearchMerchantsNameChange = (value) => {
    this.setState({ searchMerchantName: value });
  };

  handleSearchMerchantIdChange = (value) => {
    this.setState({ searchMerchantId: value });
  };

  renderRedemptionsDrawer = () => {
    return (
      <div className={styles.drawer_div}>
        {this.state.redemptionIds.length === 0 ? (
          <span>No Redemption Available</span>
        ) : (
          <div>
            <Table>
              <thead>
                <th className={styles.table_filter_search} colSpan={4}>
                  <div className={styles.table_search}>
                    <DateFilter
                      label='Period'
                      onBtnPress={this.handleFilter}
                      onClear={this.handleRedemptionFilterClear}
                      onStartDateChange={this.handleOnStartDateChange}
                      onEndDateChange={this.handleOnEndDateChange}
                    />
                    <Search
                      placeholder='Redemption ID'
                      onBtnPress={this.handleSearch}
                      onClearPress={this.handleClear}
                      max={10}
                      onChangeKey={this.handleOnSearchRedemptionIdChange}
                    />
                  </div>
                </th>
              </thead>
              {this.state.searching ||
              (this.state.filtering && this.state.isFiltered) ? (
                <div className={styles.not_available}>
                  {this.state.search_filter_redemptions.length === 0 &&
                    this.renderNotAvailable(
                      `No redemptions ${this.state.searchedRedemption}`
                    )}
                </div>
              ) : (
                <thead>
                  <th></th>
                  <th>Date</th>
                  <th>Redemption ID</th>
                  <th>Amount (LKR)</th>
                </thead>
              )}
              <tbody>
                {!this.state.isFiltered || !this.state.isSearched ? (
                  <td colSpan={4}>
                    <Loading text='Loading' />
                  </td>
                ) : this.state.searching || this.state.filtering ? (
                  this.state.search_filter_redemptions.length > 0 ? (
                    this.renderRedemptions(this.state.search_filter_redemptions)
                  ) : null
                ) : (
                  this.renderRedemptions(this.state.redemptionIds)
                )}
              </tbody>
            </Table>
          </div>
        )}
        {this.state.total > 1 ? (
          <Pagination
            pageCount={this.state.total}
            onChange={this.handlePagination}
            currentPage={this.state.current}
          />
        ) : null}
      </div>
    );
  };

  renderMerchantDrawer = () => {
    return (
      <div className={styles.merchantDrawer_div}>
        {this.state.merchantIds.length === 0 ? (
          <span>No Merchants Available</span>
        ) : (
          <div>
            <Table>
              <thead>
                <th className={styles.table_filter_search} colSpan={3}>
                  <div className={styles.table_search}>
                    <Search
                      placeholder='Merchant Name'
                      onBtnPress={this.handleMerchantNameSearch}
                      onClearPress={this.handleMerchantsClear}
                      max={20}
                      handleValueFromParent
                      value={this.state.searchMerchantName}
                      onChange={this.handleSearchMerchantsNameChange}
                    />
                    <Search
                      placeholder='Merchant Id'
                      onBtnPress={this.handleMerchantSearch}
                      onClearPress={this.handleMerchantsClear}
                      max={50}
                      handleValueFromParent
                      value={this.state.searchMerchantId}
                      onChange={this.handleSearchMerchantIdChange}
                    />
                  </div>
                </th>
              </thead>
              <thead>
                <th></th>
                <th>Name</th>
                <th>ID</th>
              </thead>
              <tbody>
                {this.state.slicedMerchantIds.length > 0
                  ? this.state.slicedMerchantIds.map((option) => (
                      <tr className={styles.data}>
                        <td>
                          <input
                            type='radio'
                            name='option'
                            onChange={() => {
                              this.handleMerchantIdOnChange(
                                option.name,
                                option.id
                              );
                            }}
                            value={option.id}
                            checked={
                              this.state.merchantId &&
                              this.state.merchantId === option.id
                            }
                          />
                        </td>
                        <td>
                          <span value={option} id={option}>
                            {option.name}
                          </span>
                        </td>
                        <td>
                          <span value={option} id={option}>
                            {option.id}
                          </span>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
            {this.state.isMerchantSearched ? (
              <div className={styles.not_available}>
                {this.state.slicedMerchantIds.length === 0
                  ? this.renderNotAvailable(
                      `No merchant ${this.state.searchedMerchant}`
                    )
                  : null}
              </div>
            ) : null}
          </div>
        )}
        {this.state.merchantpaginationIds.length > 10 && (
          <Pagination
            pageCount={this.state.merchantPages}
            onChange={this.handleMerchantPagination}
            currentPage={0}
          />
        )}
      </div>
    );
  };

  renderCancelPopup = () => {
    return (
      <Modal
        show={this.state.showCancelModal}
        onHide={() => {
          this.handlePopupState(false);
        }}
        backdrop='static'
        keyboard={false}
        animation={false}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title>Cancel</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to cancel ? </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              this.handlePopupState(false);
            }}
          >
            No
          </Button>
          <Button variant='primary' onClick={this.handleCancelPayment}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleOnPaymentDateChange = (date) => {
    this.setState({ paymentDate: date });
  };

  render() {
    return (
      <div className={styles.div_root}>
        <Accordion defaultActiveKey='0'>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant='link' eventKey='0'>
                Select Merchant Here
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey='0'>
              <Card.Body>
                {this.state.loading ? (
                  <Loading text='Loading' />
                ) : (
                  this.renderMerchantDrawer()
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant='link' eventKey='1'>
                {`Select Redemptions of ${this.state.merchantName} Here`}
              </Accordion.Toggle>
            </Card.Header>

            <Accordion.Collapse eventKey='1'>
              <Card.Body>
                {this.state.redemptionLoading ? (
                  <Loading text='Loading' />
                ) : (
                  this.renderRedemptionsDrawer()
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Card className={styles.amount_payable_div}>
          <span className={styles.amount_payable_line01}>
            {`Number of redemption(s) selected : ${this.selectedRedemptionCount()}`}
          </span>
          <span className={styles.amount_payable}>
            {`Total amount to pay : LKR ${this.state.totalAmount
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          </span>
        </Card>

        <div className='text-center'>
          {this.state.isSuccess
            ? this.renderSuccessMessage()
            : this.state.addError &&
              this.renderErrorMessage(this.state.addError)}
        </div>

        {this.renderCancelPopup()}
        <div className={styles.div_payment_details}>
          <p>Payment Date</p>
          <DatePicker
            className={styles.date_picker__input}
            selected={this.state.paymentDate}
            onChange={this.handleOnPaymentDateChange}
            selectsStart
            startDate={null}
            endDate={null}
            maxDate={Date.now()}
            placeholderText={moment(Date.now()).format('DD/MM/YYYY')}
            isClearable
            dateFormat='dd/MM/yyyy'
          />
        </div>
        <div className={styles.bottomBtn}>
          <div className={styles.div_addBtn}>
            <Button
              variant='secondary'
              block
              onClick={() => {
                this.handlePopupState(true);
              }}
              disabled={this.state.disableCancel}
            >
              CANCEL
            </Button>
          </div>
          <div className={styles.div_addBtn}>
            <Button
              variant='dark'
              block
              onClick={this.handleOnSubmit}
              disabled={this.state.loading}
            >
              {this.state.isApiCalled ? 'Loading..' : 'ADD PAYMENT'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(AddPayment);
