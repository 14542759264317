import React from 'react';
import moment from 'moment';

import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './DataTable.module.scss';

const DataTable = ({
  tableData,
  tableheading,
  handleOnClick,
  tag,
  fetchError,
  search_filter_loading,
  notFoundMessage,
  merchant,
}) => {
  const renderTableHeadings = () => {
    return tableheading.column.map((data, index) => {
      return (
        <th key={index} className={styles.tableHead} align='center'>
          {data}
        </th>
      );
    });
  };

  const renderTableData = () => {
    switch (tag) {
      case 'terminal':
        return tableData.map((data) => {
          return renderTerminalData(data);
        });
      case 'payments':
        return tableData.map((data) => {
          return renderPaymentData(data);
        });
      case 'merchants':
        return tableData.map((data) => {
          return renderMerchantData(data);
        });
      case 'subMerchants':
        return tableData.map((data) => {
          return renderSubMerchantData(data);
        });
      case 'redemptions':
        return tableData.map((data) => {
          return renderRedemptionsData(data);
        });
      case 'userDetails':
        return tableData.map((data) => {
          return renderUserDetails(data);
        });
      default:
        return;
    }
  };

  const renderUserDetails = (data) => {
    return (
      <tr key={data.email}>
        <td>{data.firstName}</td>
        <td>{data.lastName}</td>
        <td>{data.email}</td>
        <td>{data.mobileNo}</td>
      </tr>
    );
  };

  const renderRedemptionsData = (data) => {
    return (
      <tr>
        <td>{data.id}</td>
        <td>
          {data.merchantEarning &&
            data.merchantEarning
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </td>
      </tr>
    );
  };

  const renderSubMerchantData = (data) => {
    return (
      <tr>
        <td>{data.id}</td>
        <td>{data.emails.join(', ')}</td>
        <td>{data.location}</td>
      </tr>
    );
  };

  const renderMerchantData = (data) => {
    return (
      <tr onClick={() => handleOnClick(data.id)}>
        <td>{data.id}</td>
        <td>{data.name}</td>
        <td>{data.category}</td>
        <td>{data.status}</td>
        <td>{data.website}</td>
        <td>{data.tel}</td>
        <td>{data.voucherCount}</td>
      </tr>
    );
  };

  const renderTerminalData = (data) => {
    return (
      <tr onClick={() => handleOnClick(data.id)}>
        <td>{data.id}</td>
        <td>{data.merchant.name}</td>
        <td>{data.subMerchant.id}</td>
        <td>{data.lastActive}</td>
      </tr>
    );
  };

  const renderPaymentData = (data) => {
    return (
      <tr onClick={() => handleOnClick(data.id)}>
        <td>{moment(data.createdTimestamp).format('DD/MM/YYYY hh:mm A')}</td>
        <td>{data.id}</td>
        <td>{data.referenceCode}</td>
        {!merchant && <td>{data.coreMerchant.name}</td>}
        <td>
          <span className='d-flex justify-content-end w-50'>
            {data.amount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </span>
        </td>
      </tr>
    );
  };

  const renderNotFound = (content) => {
    return (
      <tr>
        <td
          colSpan={tableheading.column && tableheading.column.length}
          className='text-secondary bg-white text-center'
        >
          <span className='py-4'>{content}</span>
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <div className='w-100'>
        <Table striped bordered hover>
          <thead>
            <tr>{renderTableHeadings()}</tr>
          </thead>
          <tbody>
            {search_filter_loading ? (
              <tr>
                <td
                  colSpan={tableheading.column && tableheading.column.length}
                  className='text-secondary bg-white text-center'
                >
                  <Spinner animation='border' />
                </td>
              </tr>
            ) : fetchError === '' && tableData.length > 0 ? (
              renderTableData()
            ) : notFoundMessage ? (
              renderNotFound(notFoundMessage)
            ) : (
              renderNotFound(`NO ${tag.toUpperCase()} FOUND`)
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  return <div className={styles.root__div}>{renderTable()}</div>;
};

export default DataTable;
