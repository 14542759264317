import { GET, POST, PUT } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/terminals/${endpoint}`;
};

export const getAllTerminals = (token) => {
  const endpoint = getEndpointWithPrefix('getAllTerminals');
  return GET(endpoint, token);
};

export const saveTerminal = (terminal, token) => {
  const endpoint = getEndpointWithPrefix('saveTerminal');
  return POST(endpoint, terminal, token);
};

export const saveNewPin = (update, token) => {
  const endpoint = getEndpointWithPrefix('savePin');
  return PUT(endpoint, update, token);
};
