import { POST, GET } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/authentication/${endpoint}`;
};

export const login = (authData) => {
  const endpoint = getEndpointWithPrefix('sign-in');
  const body = {
    ...authData,
  };
  return POST(endpoint, body);
};

export const getUserDetails = (token) => {
  const endpoint = getEndpointWithPrefix('user-details');

  return GET(endpoint, token);
};

export const getMerchantList = (email, token) => {
  const endpoint = getEndpointWithPrefix(`getMerchantList/${email}`);

  return GET(endpoint, token);
};

export const updateAdminAuth = (request, token) => {
  const endpoint = getEndpointWithPrefix('updateAdminAuth');

  return POST(endpoint, request, token);
};
