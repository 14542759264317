import React, { useEffect } from 'react';
import { Drawer } from 'rsuite';
import moment from 'moment';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import {
  getAllOffers,
  getOffersByMerchantName,
  getOffersByDates,
  updateOffer,
  getOffersByName,
} from '../../api/offer';
import useComponentState from '../../utils/useComponentState';
import { getAllMerchant } from '../../api/merchant';

import { Loading, PageHeader, OfferItem, Pagination } from '../../components';
import SearchByDate from '../../components/SearchByDate/SearchByDate.components';
import SearchByRedemtionIdOrReferanceCode from '../../components/SearchByRedemtionIdOrReferanceCode/SearchByRedemtionIdOrReferanceCode.component';
import MerchantSelector from '../../components/MerchantSelector/MerchantSelector';

import 'rsuite/dist/styles/rsuite-default.css';
import styles from './ViewOffers.module.scss';

const ViewOffers = (props) => {
  const [state, setState] = useComponentState({
    offersLoading: true,
    offers: {
      total: 0,
      current: 0,
      offers: [],
    },
    offersError: null,
    selectedOffer: null,
    startDate: null,
    endDate: null,
    merchantName: '',
    filter: false,
    search: false,
    notFoundMessage: null,
    selectedMerchant: '',
    merchantList: [],
    description: '',
    endingDate: null,
    descriptionError: null,
    endDateError: null,
    updateOfferLoading: false,
    updateOfferSuccess: false,
    updateError: null,
    offerName: '',
    merchantShortList: [],
    slicedMerchantIds: [],
    paginateList: [],
    merchantPages: 0,
    showMerchantDrawer: false,
    isMerchantSearched: false,
    searchedMerchantId: '',
    searchedMerchantName: '',
    selectedMerchantId: '',
    offerSearch: false,
  });

  const {
    offersLoading,
    offers: { total, current, offers },
    offersError,
    selectedOffer,
    updateOfferSuccess,
    updateOfferLoading,
    endDateError,
  } = state;

  const {
    auth: { token },
  } = props;

  useEffect(() => {
    handleGetOffersFromApi(0);
    getMerchants();
    //eslint-disable-next-line
  }, []);

  const getMerchants = async () => {
    try {
      const response = await getAllMerchant(token);
      const sortList = sortMerchantList(response);
      setState({
        merchantList: sortList,
        merchantShortList: sortList.slice(0, 5),
        slicedMerchantIds: sortList.slice(0, 10),
        merchantPages: calculatepages(sortList),
        paginateList: sortList,
      });
    } catch (err) {
      setState({ offersLoading: false, offersError: err.message });
    }
  };

  const sortMerchantList = (data) => {
    return data.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
  };

  const calculatepages = (data) => {
    var pages = 0;
    var mod = data.length % 10;
    if (mod > 0) {
      pages = data.length / 10 - mod / 10 + 1;
    } else {
      pages = data.length / 10;
    }
    return pages;
  };

  const sortOffersByDate = (data) => {
    return data.sort((a, b) => (a.startDate < b.startDate ? 1 : -1));
  };

  const handleOnPagechange = (value) => {
    if (state.filter) {
      getOffersByDatesApi(state.startDate, state.endDate, value);
    } else if (state.search) {
      getOffersByMerchantNameApi(value, state.merchantName);
    } else if (state.offerSearch) {
      getOffersByNameApi(state.offerName, value);
    } else {
      handleGetOffersFromApi(value);
    }
  };

  const handleGetOffersFromApi = async (value) => {
    try {
      setState({
        offersLoading: true,
        offersError: null,
        notFoundMessage: null,
      });
      const response = await getAllOffers(value, token);
      setState({ offersLoading: false, offers: { ...response } });
      if (response.offers.length === 0) {
        setState({ notFoundMessage: 'No offers found' });
      }
    } catch (err) {
      setState({ offersLoading: false, offersError: err.message });
    }
  };

  const getOffersByMerchantNameApi = async (page, merchant) => {
    try {
      setState({
        offersLoading: true,
        offersError: null,
        notFoundMessage: null,
      });
      const request = {
        merchant,
        page,
      };
      const response = await getOffersByMerchantName(request, token);
      setState({
        offersLoading: false,
        offers: response,
        search: true,
        filter: false,
      });
      if (response.offers.length === 0) {
        setState({
          notFoundMessage: `No offers found for merchant name ${merchant}`,
        });
      }
    } catch (err) {
      setState({ offersLoading: false, offersError: err.message });
    }
  };

  const getOffersByDatesApi = async (startDate, endDate, page) => {
    try {
      setState({
        offersLoading: true,
        offersError: null,
        notFoundMessage: null,
      });
      const request = {
        startDate: moment(startDate)
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        page,
      };
      const response = await getOffersByDates(request, token);
      setState({
        offersLoading: false,
        offers: response,
        filter: true,
        search: false,
      });
      if (response.offers.length === 0) {
        setState({
          notFoundMessage: `No offers found for ${formatDate(
            startDate
          )} - ${formatDate(endDate)}`,
        });
      }
    } catch (err) {
      setState({ offersLoading: false, offersError: err.message });
    }
  };

  const getOffersByNameApi = async (name, page) => {
    try {
      setState({
        offersLoading: true,
        offersError: null,
        notFoundMessage: null,
      });
      const request = {
        offerName: name,
        page,
      };
      const response = await getOffersByName(request, token);
      setState({
        offersLoading: false,
        offers: response,
        search: false,
        filter: false,
        offerSearch: true,
        notFoundMessage:
          response.offers.length === 0
            ? `No offers found for name ${name}`
            : null,
      });
    } catch (err) {
      setState({ offersLoading: false, offersError: err.message });
    }
  };

  const handleUpdateOffer = async () => {
    try {
      const { selectedOffer, description, endingDate } = state;
      const request = {
        id: selectedOffer.id,
        description,
        endDate: moment(endingDate)
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss'),
      };
      setState({ updateOfferLoading: true });
      await updateOffer(request, token);
      setState({ updateOfferLoading: false, updateOfferSuccess: true });
      handleGetOffersFromApi(0);
    } catch (err) {
      setState({
        updateError: err.message,
        updateOfferSuccess: false,
        updateOfferLoading: false,
      });
    }
  };

  const formatDate = (date) => {
    return moment(date).format('DD-MM-YYYY');
  };

  const onChangeStartDate = (startDate) => {
    const { endDate } = state;
    setState({ startDate });
    if (startDate && endDate) {
      getOffersByDatesApi(startDate, endDate, 0);
    }
    if (!startDate && !endDate) {
      handleGetOffersFromApi(0);
    }
  };

  const onChangeEndDate = (endDate) => {
    const { startDate } = state;
    setState({ endDate });
    if (startDate && endDate) {
      getOffersByDatesApi(startDate, endDate, 0);
    }
    if (!startDate && !endDate) {
      handleGetOffersFromApi(0);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= 320) {
      setState({
        [name]: value,
        descriptionError: null,
      });
    }
  };

  const handleSearchByOfferName = (event) => {
    event.preventDefault();
    const value = state.offerName;
    if (value) {
      getOffersByNameApi(value, 0);
    }
  };

  const offerNameOnChange = (e) => {
    setState({
      offerName: e.target.value,
    });
  };

  const onDateChange = (date) => {
    setState({
      endingDate: date,
    });
  };

  const offerNameOnClean = () => {
    setState({
      offerName: '',
      notFoundMessage: null,
      filter: false,
      search: false,
      offerSearch: false,
    });
    handleGetOffersFromApi(0);
  };

  const handleMerchantOnChange = (value) => {
    setState({ selectedMerchant: value });
    switch (value) {
      case 'All Merchants':
        handleGetOffersFromApi(0);
        break;
      case 'Load more':
        setState({
          showMerchantDrawer: true,
          selectedMerchant: 'All Merchants',
        });
        break;
      default:
        handleMerchantDefaultCase(value);
    }
  };

  const handleMerchantDefaultCase = (value) => {
    getOffersByMerchantNameApi(0, value);
  };

  const handleMerchantIdSearch = (id) => {
    if (id) {
      const item = state.merchantList.filter(
        (item) => item.id.indexOf(id) > -1
      );

      const sortList = sortMerchantList(item);
      setState({
        slicedMerchantIds: item.length > 0 ? sortList.slice(0, 10) : item,
        isMerchantSearched: true,
        searchedMerchantId: id,
        searchedMerchantName: '',
        merchantPages: calculatepages(item),
        paginateList: sortList,
      });
    }
  };

  const handleMerchantNameSearch = (name) => {
    if (name) {
      const item = state.merchantList.filter((item) => {
        let itemData = item.name.toLowerCase();
        return itemData.indexOf(name.toLowerCase()) > -1;
      });

      const sortList = sortMerchantList(item);
      setState({
        slicedMerchantIds: item.length > 0 ? sortList.slice(0, 10) : item,
        isMerchantSearched: true,
        searchedMerchantName: name,
        searchedMerchantId: '',
        merchantPages: calculatepages(item),
        paginateList: sortList,
      });
    }
  };

  const handleMerchantIdOnChange = (name, id) => {
    setState({
      selectedMerchantId: id,
      selectedMerchant: name,
    });
    getOffersByMerchantNameApi(0, name);
  };

  const handlMerchantsclear = () => {
    setState({
      slicedMerchantIds: state.merchantList.slice(0, 10),
      searchedMerchantName: '',
      searchedMerchantId: '',
      isMerchantSearched: false,
      paginateList: state.merchantList,
    });
  };

  const handleMerchantpagination = (page) => {
    let sliceStart = 0;
    let sliceEnd = 10;
    if (page > 0) {
      sliceStart = page * 10;
      sliceEnd = (page + 1) * 10;
    }
    setState({
      slicedMerchantIds: state.paginateList.slice(sliceStart, sliceEnd),
    });
  };

  const handleOnHideMerchantSelector = () => {
    setState({
      showMerchantDrawer: false,
      slicedMerchantIds: state.merchantList.slice(0, 10),
      searchedMerchantName: '',
      searchedMerchantId: '',
      isMerchantSearched: false,
      paginateList: state.merchantList,
    });
  };

  const updateOnClick = () => {
    const { description, endingDate } = state;
    if (!description.trim()) {
      setState({ descriptionError: 'Description cannot be empty' });
    } else if (!endingDate) {
      setState({ endDateError: 'EndDate cannot be empty' });
    } else {
      handleUpdateOffer();
    }
  };

  const getUpadateBtnAvailability = () => {
    const { selectedOffer, description, endingDate } = state;
    let disable = true;
    if (
      selectedOffer.description !== description ||
      selectedOffer.endDate !== endingDate
    ) {
      disable = false;
    }
    return disable;
  };

  const renderNotFound = () => {
    return (
      <div className={styles.not_found}>
        <span>{state.notFoundMessage}</span>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.loading}>
        <Loading text='Loading' />
      </div>
    );
  };

  const renderError = () => {
    return <div>{offersError}</div>;
  };

  const handleOnOfferDeselect = () => {
    setState({
      selectedOffer: null,
      description: '',
      endingDate: null,
      updateError: null,
      updateOfferLoading: false,
      updateOfferSuccess: false,
      descriptionError: null,
      endDateError: null,
    });
  };

  const handleOnOfferSelect = (offer) => {
    setState({
      selectedOffer: { ...offer },
      description: offer.description,
      endingDate: offer.endDate,
    });
  };

  const renderDrawerMerchantOption = () => {
    var exist = false;
    state.merchantShortList.filter((merchant) => {
      if (state.selectedMerchant === merchant.name) {
        exist = true;
      }
    });

    if (!exist) {
      return <option>{state.selectedMerchant}</option>;
    }
  };

  const renderMerchantDropDown = () => {
    const { selectedMerchant } = state;
    return (
      <Form.Control
        as='select'
        onChange={(e) => handleMerchantOnChange(e.target.value)}
        value={state.selectedMerchant}
      >
        <option>All Merchants</option>
        {state.merchantShortList.map((item) => {
          return <option key={item.id}>{item.name}</option>;
        })}
        {selectedMerchant &&
          selectedMerchant !== 'All Merchants' &&
          renderDrawerMerchantOption()}
        <option className={styles.load_more}>Load more</option>
      </Form.Control>
    );
  };

  const renderTextArea = () => {
    const { description, descriptionError } = state;
    return (
      <Form.Group as={Row}>
        <Col>
          <Form.Control
            aria-label='desc'
            aria-describedby='basic-addon2'
            name='description'
            value={description}
            onChange={onChange}
            required
            as='textarea'
            rows={3}
            maxLength={320}
            placeholder='Description'
          />
          {descriptionError && (
            <span className={styles.error_msg}>{descriptionError}</span>
          )}
        </Col>
      </Form.Group>
    );
  };

  const renderDatePicker = () => {
    return (
      <DatePicker
        className={styles.date_picker__input}
        selected={new Date(state.endingDate)}
        onChange={(date) => onDateChange(date)}
        selectsStart
        placeholderText='End date'
        isClearable
        dateFormat='dd/MM/yyyy'
      />
    );
  };

  const renderSelectedOffer = () => {
    const {
      code,
      title,
      description,
      merchantContribution,
      platformContribution,
      unit,
      startDate,
      endDate,
    } = selectedOffer;

    const unitVal = unit === 'OFFER_DISCOUNT' ? '%' : 'LKR';

    return (
      <div className={styles.selected__offer}>
        <div className={styles.offer__child}>
          <div className={styles.drawer__header_block}>
            <span className={styles.drawer__header}>Offer Code : </span>
            <span className={styles.drawer__header__value}> {code} </span>
          </div>
          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Offer Name</span>
              <span className={styles.detail__value}>{title}</span>
            </div>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Offer Type</span>
              <span className={styles.detail__value}>
                {unit === 'OFFER_DISCOUNT' ? 'Discount' : 'Flat Rate'}
              </span>
            </div>
          </div>
          <div className={styles.detail__group}>
            <span className={styles.detail__header}>Description</span>
            {renderTextArea()}
          </div>
          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>
                Merchant Contribution
              </span>
              <span
                className={styles.detail__value}
              >{`${merchantContribution} ${unitVal}`}</span>
            </div>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>
                Platform Contribution
              </span>
              <span
                className={styles.detail__value}
              >{`${platformContribution} ${unitVal}`}</span>
            </div>
          </div>
          <div className={styles.details__horizontal}>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>Start Date</span>
              <span className={styles.detail__value}>
                {moment(startDate).format('DD-MM-YYYY')}
              </span>
            </div>
            <div className={styles.detail__group__nested}>
              <span className={styles.detail__header}>End Date</span>
              {renderDatePicker()}
              {endDateError && (
                <span className={styles.error_msg}>{endDateError}</span>
              )}
            </div>
          </div>
        </div>
        <div className={styles.detail__group}>
          <Button
            variant='dark'
            onClick={updateOnClick}
            disabled={getUpadateBtnAvailability()}
          >
            {updateOfferLoading ? 'Loading' : 'Update'}
          </Button>
        </div>
        <div className={styles.detail__group}>
          {updateOfferSuccess && (
            <span className={styles.success_response}>
              Successfully updated
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderOffers = () => {
    const offersToRender = sortOffersByDate(offers).map((offer) => {
      return <OfferItem offer={offer} onClick={handleOnOfferSelect} />;
    });
    return (
      <div className={styles.offers__list}>
        <PageHeader text='Offers' />
        <div className={styles.search_area_block}>
          <SearchByDate
            onStartDateChange={onChangeStartDate}
            onEndDateChange={onChangeEndDate}
            startDate={state.startDate}
            endDate={state.endDate}
          />
          <div className={styles.separator} />
          <SearchByRedemtionIdOrReferanceCode
            onSubmit={handleSearchByOfferName}
            onChange={offerNameOnChange}
            value={state.offerName}
            placeholder='Offer Name'
            onClean={offerNameOnClean}
          />
          <div className={styles.separator} />
          {renderMerchantDropDown()}
        </div>
        <div className={styles.table__header}>
          <span>ID</span>
          <span>Name</span>
          <span>Merchant</span>
          <span>Expiry Date</span>
          <span>Status</span>
        </div>
        {offersLoading
          ? renderLoading()
          : state.notFoundMessage
          ? renderNotFound()
          : offersToRender}
        {total > 1 && (
          <Pagination
            pageCount={total}
            onChange={handleOnPagechange}
            currentPage={current}
          />
        )}
        <Drawer show={selectedOffer} onHide={handleOnOfferDeselect}>
          {selectedOffer && renderSelectedOffer()}
        </Drawer>
      </div>
    );
  };

  return (
    <div className={styles.root__div}>
      {offersError ? renderError() : renderOffers()}
      <Drawer
        show={state.showMerchantDrawer}
        onHide={handleOnHideMerchantSelector}
      >
        <MerchantSelector
          data={state.slicedMerchantIds}
          merchantId={state.selectedMerchantId}
          merchantPageCount={state.merchantPages}
          handleMerchantSearchById={handleMerchantIdSearch}
          handleMerchantSearchByName={handleMerchantNameSearch}
          handleMerchantPagination={handleMerchantpagination}
          handleMerchantIdOnChange={handleMerchantIdOnChange}
          handlMerchantsclear={handlMerchantsclear}
          searchedMerchantName={state.searchedMerchantName}
          searchedMerchantId={state.searchedMerchantId}
          paginateList={state.paginateList}
        />
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewOffers);
