export const POST = (endpoint, requestBody = {}, authorization = null) => {
  const url = createRequestUrl(endpoint);
  const body = createRequestBody(requestBody);
  const headers = createRequestHeader(authorization);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body,
      });
      const data = await response.json();
      if (response.status >= 400) {
        reject(data);
      }
      resolve(data);
    } catch (err) {
      if (
        err.message &&
        err.message === 'NetworkError when attempting to fetch resource.'
      ) {
        err.message = 'Network Error. Please try again.';
      } else if (err.message && err.message === 'SERVER_ERROR') {
        err.message = 'Could not connect to server. Please try again.';
      }
      reject(err);
    }
  });
};

export const GET = (endpoint, authorization = null) => {
  const url = createRequestUrl(endpoint);
  const headers = createRequestHeader(authorization);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers,
      });
      const data = await response.json();
      if (response.status >= 400) {
        reject(data);
      }
      resolve(data);
    } catch (err) {
      if (
        err.message &&
        err.message === 'NetworkError when attempting to fetch resource.'
      ) {
        err.message = 'Network Error. Please try again.';
      } else if (err.message && err.message === 'SERVER_ERROR') {
        err.message = 'Could not connect to server. Please try again.';
      }
      reject(err);
    }
  });
};

export const PUT = (endpoint, requestBody = {}, authorization) => {
  const url = createRequestUrl(endpoint);
  const body = createRequestBody(requestBody);
  const headers = createRequestHeader(authorization);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers,
        body,
      });
      const data = await response.json();
      if (response.status >= 400) {
        reject(data);
      }
      resolve(data);
    } catch (err) {
      if (
        err.message &&
        err.message === 'NetworkError when attempting to fetch resource.'
      ) {
        err.message = 'Network Error. Please try again.';
      } else if (err.message && err.message === 'SERVER_ERROR') {
        err.message = 'Could not connect to server. Please try again.';
      }
      reject(err);
    }
  });
};

const createRequestUrl = (endpoint) => {
  return `${process.env.REACT_APP_BASE_URL}${endpoint}`;
};

const createRequestBody = (requestBody) => {
  return JSON.stringify(requestBody);
};

const createRequestHeader = (authorization) => {
  return authorization
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorization}`,
      }
    : {
        'Content-Type': 'application/json',
      };
};
