import { GET, POST } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/user/${endpoint}`;
};

export const getAllUserDetails = (token) => {
  const endpoint = getEndpointWithPrefix('');
  return GET(endpoint, token);
};

export const filterUser = (token, data) => {
  const endpoint = getEndpointWithPrefix('filter');
  return POST(endpoint, data, token);
};
