import { GET, POST } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/sub-merchants/${endpoint}`;
};

export const getSubMerchantsByMerchant = (merchantId, token) => {
  const endpoint = getEndpointWithPrefix(`by-merchant/${merchantId} `);

  return GET(endpoint, token);
};

export const getAllSubMerchantIds = (token) => {
  const endpoint = getEndpointWithPrefix('getAllSubMerchantsID');
  return GET(endpoint, token);
};

export const addNewSubmerchant = (request, token) => {
  const endpoint = getEndpointWithPrefix('addNewSubMerchant');
  return POST(endpoint, request, token);
};

export const getDashboard = (submerchantId, token) => {
  const endpoint = getEndpointWithPrefix(`dashboard/${submerchantId} `);
  return GET(endpoint, token);
};
