import React from 'react';
import { Button } from 'react-bootstrap';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import ReactPasswordStrength from 'react-password-strength';

import useComponentState from '../../utils/useComponentState';

import styles from './ForgotPassword.module.scss';
import './ForgotPassword.module.scss';
import './CustomInput.scss';

const ResetPassword = (props) => {
  const { onSubmit, onChange, handleGoBack, value, error } = props;

  const [state, setState] = useComponentState({
    newPassword: value,
    confirmPassword: '',
    isPasswordVisible: false,
    isConfirmPasswordVisible: false,
    passwordError: '',
    confirmationPasswordError: '',
    newPasswordStatus: 0,
  });

  const {
    newPassword,
    confirmPassword,
    isPasswordVisible,
    isConfirmPasswordVisible,
    passwordError,
    confirmationPasswordError,
    newPasswordStatus,
  } = state;

  const handlefieldOnChange = (data) => {
    setState({
      passwordError: '',
      confirmationPasswordError: '',
    });
    if (data.target.name === 'newPassword') {
      onChange(data.target.value);
      setState({ newPassword: data.target.value });
    } else {
      setState({ confirmPassword: data.target.value });
    }
  };

  const onSubmitt = (event) => {
    event.preventDefault();
    setState({ passwordError: '', confirmationPasswordError: '' });

    if (!newPassword) {
      setState({
        passwordError: "New password can't be empty",
      });
    } else {
      if (passwordLengthCheck(newPassword)) {
        setState({
          passwordError: 'Use 8 characters or more for your password',
        });
      } else if (newPasswordStatus < 1) {
        setState({
          passwordError:
            'Please choose a stronger password. Try a mix of letters, numbers and symbols',
        });
      }
    }

    if (!confirmPassword) {
      setState({
        confirmationPasswordError: "Confirmation password can't be empty",
      });
    } else if (!passwordIsEqual(newPassword)) {
      setState({
        confirmationPasswordError: 'Passwords do not match',
      });
    }

    if (
      !passwordLengthCheck(newPassword) &&
      passwordIsEqual(newPassword) &&
      newPasswordStatus > 0
    ) {
      onSubmit();
    }
  };

  const handleIsPasswordVisible = () => {
    setState({ isPasswordVisible: !isPasswordVisible });
  };

  const handleIsConfirmPasswordVisible = () => {
    setState({ isConfirmPasswordVisible: !isConfirmPasswordVisible });
  };

  const passwordLengthCheck = (password) => {
    return password.length > 0 && password.length < 8;
  };

  const passwordIsEqual = (password) => {
    return password === confirmPassword;
  };

  const onNewPassworChange = (date) => {
    setState({ newPasswordStatus: date.score });
  };

  return (
    <div>
      <div className={styles.forgotpw__sub_title}>
        Reset your password here!
      </div>
      <Formik
        initialValues={{
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        }}
        validationSchema={Yup.object().shape({
          newPassword: Yup.string().required("New password can't be empty"),
          confirmPassword: Yup.string()
            .required("Confirmation password can't be empty")
            .test('passwords-match', 'Passwords do not match', (password) => {
              return password === value;
            }),
        })}
        onSubmit={onSubmitt}
      >
        <Form
          onSubmit={onSubmitt}
          onChange={handlefieldOnChange}
          className={styles.root_form}
        >
          <div className='mb-3 w-100'>
            <div className={styles.reset__group}>
              <ReactPasswordStrength
                style={{ backgroundColor: 'red' }}
                className='ReactPasswordStrength'
                minLength={8}
                minScore={2}
                required
                tooShortWord='Too short'
                scoreWords={['Weak', 'Okay', 'Good', 'Strong', 'Stronger']}
                inputProps={{
                  name: 'newPassword',
                  placeholder: 'New Password',
                  type: isPasswordVisible ? 'text' : 'password',
                  value: newPassword,
                }}
                changeCallback={onNewPassworChange}
                onChange={handlefieldOnChange}
              />
              <button
                type='button'
                className={styles.toggle__container}
                onClick={handleIsPasswordVisible}
              >
                <i
                  className={
                    isPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'
                  }
                ></i>
              </button>
            </div>
            {passwordError && (
              <small className='text-danger'>{passwordError}</small>
            )}
          </div>
          <Form.Group className='w-100'>
            <div className={styles.confirm__container}>
              <Form.Control
                name='confirmPassword'
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                placeholder='Confirm Password'
                value={confirmPassword}
                onChange={handlefieldOnChange}
                onPaste={(e) => e.preventDefault()}
                className={styles.form_input}
              />
              <button
                type='button'
                className={styles.confirm__toggle}
                onClick={handleIsConfirmPasswordVisible}
              >
                <i
                  className={
                    isConfirmPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'
                  }
                ></i>
              </button>
            </div>
            {confirmationPasswordError && (
              <small className={styles.forgetPassword_error}>
                {confirmationPasswordError}
              </small>
            )}
          </Form.Group>
          {error && <p className={styles.forgetPassword_error}>{error}</p>}
          <div className={styles.button__group}>
            <Button className={styles.form_button} variant='dark' type='submit'>
              RESET
            </Button>
          </div>
        </Form>
      </Formik>
      <div className={styles.navigate_back_div}>
        <Button on variant='link' onClick={handleGoBack}>
          SIGN IN
        </Button>
      </div>
    </div>
  );
};
export default ResetPassword;
