import React from 'react';

import styles from './DrawerToggle.module.scss';

const DrawerToggle = (props) => {
  const { onClick } = props;
  return (
    <div className={styles.root__div}>
      <button onClick={onClick} className={styles.drawer_navigate_button}>
        <svg
          width='2em'
          height='2em'
          viewBox='0 0 16 16'
          class='bi bi-list'
          fill='currentColor'
          xmlns='http://www.w3.org/2000/svg'
          color='white'
        >
          <path
            fill-rule='evenodd'
            d='M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'
          />
        </svg>
      </button>
    </div>
  );
};

export default DrawerToggle;
