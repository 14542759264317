export const sortBydate = (data) => {
  for (let i = 0; i < data.length + 1; i++) {
    let max_index = i;
    for (let j = i + 1; j < data.length; j++) {
      if (data[j]['dateTime'] > data[max_index]['dateTime']) {
        max_index = j;
      }
    }
    if (max_index !== i) {
      let temp = data[max_index];
      data[max_index] = data[i];
      data[i] = temp;
    }
  }
  return data;
};
