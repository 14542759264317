import React from 'react';

import styles from './OfferTypeSelect.module.scss';
import { ErrorMessage } from 'formik';

const OfferTypeSelect = (props) => {
  const { label, options, selected, onSelect, name } = props;

  const renderOption = ({ value, label }, index) => {
    const style =
      selected === value
        ? [styles.radio, styles.selected].join(' ')
        : styles.radio;
    return (
      <button
        className={style}
        type='button'
        onClick={() => {
          onSelect(name, value);
        }}
        style={index !== options.length - 1 ? { marginRight: '10px' } : {}}
      >
        {label}
      </button>
    );
  };

  return (
    <div className={styles.form__group}>
      <span className={styles.form__label}>{label}</span>
      <div className={styles.radio__group}>
        {options.map((option, index) => {
          return renderOption(option, index);
        })}
      </div>
      <ErrorMessage name={name}>
        {(message) => <span className={styles.form__error}>{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default OfferTypeSelect;
