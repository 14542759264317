import { Button } from 'react-bootstrap';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import styles from './SearchByRedemtionIdOrReferanceCode.module.scss';

const SearchByRedemtionIdOrReferanceCode = (props) => {
  const [iconState, setIconState] = useState('SEARCH');
  const { onSubmit, onChange, value, placeholder, onClean } = props;

  const handleOnSubmit = (event) => {
    event.preventDefault();
    setIconState('CLEAR');
    onSubmit(event);
  };
  const handleOnChange = (data) => {
    setIconState('SEARCH');
    onChange(data);
  };
  const handleOnClear = () => {
    setIconState('SEARCH');
    onClean && onClean();
  };

  useEffect(() => {
    //eslint-disable-next-line
    setIconState('SEARCH');
  }, [placeholder]);

  return (
    <div className={styles.root__div}>
      <form onSubmit={handleOnSubmit} className={styles.root_form}>
        <input
          type='text'
          value={value}
          onChange={handleOnChange}
          placeholder={placeholder}
        />
        {iconState === 'SEARCH' ? (
          <Button
            className={styles.button}
            variant='outline-dark'
            type='button'
            onClick={handleOnSubmit}
          >
            <FontAwesomeIcon className={styles.button_icon} icon={faSearch} />
          </Button>
        ) : (
          <Button
            className={styles.button}
            variant='outline-dark'
            type='button'
            onClick={handleOnClear}
          >
            <FontAwesomeIcon className={styles.button_icon} icon={faTimes} />
          </Button>
        )}
      </form>
    </div>
  );
};
export default SearchByRedemtionIdOrReferanceCode;
