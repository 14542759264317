import React from 'react';

import styles from './ContactUs.module.scss';
import emailLogo from './../../styles/images/contactUs/email.png';
import phoneLogo from './../../styles/images/contactUs/phone.png';
import placeholderLogo from './../../styles/images/contactUs/placeholder.png';

const ContactUs = (props) => {
  return (
    <div className={styles.root__div}>
      <p className={styles.title}>Contact Us</p>
      <p className={styles.sub_title}>
        Want to get in touch? We'd love to hear from you
        <br />
        Here's how you can reach us
      </p>
      <div className={styles.content_root_div}>
        <div className={styles.content_div}>
          <img src={phoneLogo} className={styles.content__img} alt='logo' />
          <p className={styles.content__title}>PHONE</p>
          <p className={styles.content__text}>(+94) 77 348 9131</p>
        </div>
        <div className={styles.content_div}>
          <img src={emailLogo} className={styles.content__img} alt='logo' />
          <p className={styles.content__title}>EMAIL</p>
          <p className={styles.content__text}>hello@etcetera.solutions</p>
        </div>
        <div className={styles.content_div}>
          <img
            src={placeholderLogo}
            className={styles.content__img}
            alt='logo'
          />
          <p className={styles.content__title}>ADDRESS</p>
          <p className={styles.content__text}>
            939A/1/C, Vijitha Pura Gamunu Rd, Pelawatta
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
