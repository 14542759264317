import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import './CancelAlertPopup.scss';

const CancelAlertPopup = ({
  show,
  alertMessage,
  handleYesBtnClick,
  handleNoBtnClick,
}) => {
  return (
    <Modal
      show={show}
      onHide={() => {
        handleNoBtnClick(false);
      }}
      backdrop='static'
      keyboard={false}
      animation={false}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='alert_modal_root'
    >
      <Modal.Header>
        <Modal.Title>Cancel</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{alertMessage}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={handleNoBtnClick}>
          No
        </Button>
        <Button variant='primary' onClick={handleYesBtnClick}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelAlertPopup;
