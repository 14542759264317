import { POST } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/user/${endpoint}`;
};

export const forgetPassword = (email) => {
  const endpoint = getEndpointWithPrefix(`forgotPassword/${email}`);
  return POST(endpoint);
};

export const verifyResetPasswordKey = (email, key) => {
  const endpoint = getEndpointWithPrefix('verifyResetPasswordKey');
  const body = {
    email: email,
    resetKey: key,
  };
  return POST(endpoint, body);
};

export const resetForgotPassword = (email, key, newPassword) => {
  const endpoint = getEndpointWithPrefix('resetForgotPassword');
  const body = {
    email: email,
    newPassword: newPassword,
    resetKey: key,
  };
  return POST(endpoint, body);
};
