import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'rsuite';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import DataTable from '../../components/DataTable/DataTable';
import SearchByDate from '../../components/SearchByDate/SearchByDate.components';
import SearchByRedemtionIdOrReferanceCode from '../../components/SearchByRedemtionIdOrReferanceCode/SearchByRedemtionIdOrReferanceCode.component';
import {
  getPayablesByMerchantId,
  filterMerchantPayables,
  searchMerchantPayableById,
  getDetailsForAddPayments,
  getAllMerchantpayables,
  getAllMerchantPayable,
  getAllMerchantPayableByDate,
} from '../../api/Payments';
import { sortBydate } from '../../components/hoc/SortField';
import { Loading, PageHeader, Pagination } from '../../components';

import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './ViewPayments.module.scss';

import MerchantSelector from '../../components/MerchantSelector/MerchantSelector';
import Search from '../../components/Search/Search.component';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

class ViewPayments extends Component {
  state = {
    tableheading: {
      header: 'Payments',
      column: [
        'Date (IST)',
        'Payment ID',
        'Reference Code',
        'Merchant Name',
        'Amount (LKR)',
      ],
    },
    tableheadingForMerchant: {
      header: 'Payments',
      column: ['Date (IST)', 'Payment ID', 'Reference Code', 'Amount (LKR)'],
    },
    showModal: false,
    onClickId: 'P00001',
    modalData: [],
    loading: false,
    fetchError: '',
    tableData: [],
    total: 1,
    current: 0,
    auth: {
      token: this.props.auth.token,
    },
    searchFilterLoading: false,
    searchKey: '',
    startDate: null,
    endDate: null,
    merchantList: [],
    merchantShortList: [],
    slicedMerchantIds: [],
    selectedMerchant: null,
    selectedMerchantId: null,
    merchantFetchError: null,
    optionSelected: false,
    showMerchantDrawer: false,
    merchantPages: 0,
    isMerchantSearched: false,
    isRedemptionsSearched: false,
    canClearSearch: false,
    searchedMerchantName: '',
    searchedMerchantId: '',
    searchedRedemptionId: null,
    paginateList: [],
    notFoundMessage: null,
    downloadStatus: '',
  };

  componentDidMount() {
    this.handleFetchMerchantPayable();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.currentUser !== prevProps.user.currentUser) {
      this.handleFetchMerchantPayable();
    }
  }

  handleFetchMerchantPayable = () => {
    const currentUser = this.props.user.currentUser;
    if (currentUser) {
      this.getMerchantPayables(0, currentUser.id);
      this.setState({
        selectedMerchantId: currentUser.id,
      });
    } else {
      this.getMerchantList();
      this.getAllMerchantsPayments(0);
    }
  };

  getMerchantList = async () => {
    try {
      const response = await getDetailsForAddPayments(this.props.auth.token);
      if (response) {
        const sortList = this.sortMerchantList(response);
        this.setState({
          merchantList: sortList,
          merchantShortList: sortList.slice(0, 5),
          slicedMerchantIds: sortList.slice(0, 10),
          merchantPages: this.calculateMerchantpages(response),
          paginateList: sortList,
        });
      }
    } catch (err) {
      this.setState({
        merchantFetchError: 'Failed to fetch merchants, Please try again',
      });
    }
  };

  calculateMerchantpages = (data) => {
    var merchantPage = 0;
    var mod = data.length % 10;
    if (mod > 0) {
      merchantPage = data.length / 10 - mod / 10 + 1;
    } else {
      merchantPage = data.length / 10;
    }
    return merchantPage;
  };

  sortMerchantList = (data) => {
    return data.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
  };

  getAllMerchantsPayments = async (page) => {
    try {
      this.setState({
        fetchError: '',
        loading: true,
      });

      const token = this.props.auth.token;
      const response = await getAllMerchantpayables(page, token);
      this.setState({
        tableData: response.coreMerchantPayable,
        loading: false,
        total: response.total,
        current: response.current,
        notFoundMessage: null,
      });
    } catch (err) {
      this.setState({
        loading: false,
        fetchError: 'Failed to fetch merchant payables',
      });
    }
  };

  getMerchantPayables = async (page, id) => {
    try {
      this.setState({
        fetchError: '',
        loading: true,
      });

      const { token } = this.props.auth;

      const request = {
        merchantId: id,
        page: page,
      };

      const response = await getPayablesByMerchantId(request, token);
      const sortList = sortBydate(response.coreMerchantPayable);
      this.setState({
        tableData: sortList,
        loading: false,
        total: response.total,
        current: response.current,
        notFoundMessage:
          sortList.length === 0 &&
          `No payments found for ${this.state.selectedMerchant}`,
      });
      if (this.props.user.currentUser && sortList.length === 0) {
        this.setState({
          notFoundMessage: 'NO PAYMENTS FOUND',
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
        fetchError: error,
        notFoundMessage: `No payments found for ${this.state.selectedMerchant}`,
      });
    }
  };

  handleModalShow = (bool) => {
    this.setState({
      showModal: bool,
    });
  };

  handleMerchantpagination = (page) => {
    let sliceStart = 0;
    let sliceEnd = 10;
    if (page > 0) {
      sliceStart = page * 10;
      sliceEnd = (page + 1) * 10;
    }
    this.setState({
      slicedMerchantIds: this.state.paginateList.slice(sliceStart, sliceEnd),
    });
  };

  handleMerchantIdSearch = (id) => {
    if (id) {
      const item = this.state.merchantList.filter(
        (item) => item.id.indexOf(id) > -1
      );

      const sortList = this.sortMerchantList(item);
      this.setState({
        slicedMerchantIds: item.length > 0 ? sortList.slice(0, 10) : item,
        isMerchantSearched: true,
        searchedMerchantId: id,
        searchedMerchantName: '',
        merchantPages: this.calculateMerchantpages(item),
        paginateList: sortList,
      });
    }
  };

  handleMerchantNameSearch = (name) => {
    if (name) {
      const item = this.state.merchantList.filter((item) => {
        let itemData = item.name.toLowerCase();
        return itemData.indexOf(name.toLowerCase()) > -1;
      });

      const sortList = this.sortMerchantList(item);
      this.setState({
        slicedMerchantIds: item.length > 0 ? sortList.slice(0, 10) : item,
        isMerchantSearched: true,
        searchedMerchantName: name,
        searchedMerchantId: '',
        merchantPages: this.calculateMerchantpages(item),
        paginateList: sortList,
      });
    }
  };

  handleLoadMerchants = () => {
    const result = this.state.merchantList;
    return result.map((merchant) => {
      return {
        value: merchant.id,
        label: merchant.name,
      };
    });
  };

  handleMerchantsClear = () => {
    this.setState({
      slicedMerchantIds: this.state.merchantList.slice(0, 10),
      searchedMerchantName: '',
      searchedMerchantId: '',
      isMerchantSearched: false,
      paginateList: this.state.merchantList,
      merchantPages: this.calculateMerchantpages(this.state.merchantList),
    });
  };

  handleMerchantOnChange = (value) => {
    switch (value) {
      case 'Load More':
        this.setState({ showMerchantDrawer: true, optionSelected: true });
        break;

      case 'All Merchants':
        this.handleLoadAllPayables();
        break;

      default:
        this.handleMerchantDefaultCase(value);
    }
  };

  handleLoadAllPayables = () => {
    this.setState({
      selectedMerchantId: null,
      selectedMerchant: !this.props.user.currentUser && 'All Merchants',
    });
    this.getAllMerchantsPayments(0);
  };

  handleMerchantDefaultCase = (value) => {
    var id;
    this.state.merchantShortList.filter((merchant) => {
      if (value === merchant.name) {
        this.getMerchantPayables(0, merchant.id);
        id = merchant.id;
      }
    });
    this.setState({
      selectedMerchant: value,
      optionSelected: true,
      selectedMerchantId: id,
    });
  };

  handleMerchantIdOnChange = (name, id) => {
    this.setState({
      selectedMerchantId: id,
      selectedMerchant: name,
    });
    this.getMerchantPayables(0, id);
  };

  handleRedemptionPagination = (page) => {
    if (page > 0) {
      const sliceStart = page * 10;
      const sliceEnd = (page + 1) * 10;
      this.setModalData(
        this.state.modalData.redemptions.slice(sliceStart, sliceEnd)
      );
    } else {
      const sliceStart = 0;
      const sliceEnd = 10;
      this.setModalData(
        this.state.modalData.redemptions.slice(sliceStart, sliceEnd)
      );
    }
  };

  setModalData = (slicedRedemptions) => {
    this.setState({
      modalData: {
        redemptions: this.state.modalData.redemptions,
        date: this.state.modalData.date,
        total: this.state.modalData.total,
        tableheading: this.state.modalData.tableheading,
        pageCount: this.state.modalData.pageCount,
        slicedRedemptions: slicedRedemptions,
        merchantId: this.state.modalData.merchantId,
        merchantName: this.state.modalData.merchantName,
      },
    });
  };

  handleRedemptionSearchClear = () => {
    this.setModalData(this.state.modalData.redemptions.slice(0, 10));
    this.setState({
      isRedemptionsSearched: false,
    });
  };

  handleRedemptionIdSearch = (input) => {
    if (input) {
      const items = this.state.modalData.redemptions.filter((item) => {
        const itemData = item.id.toString();
        return itemData.indexOf(input) > -1;
      });
      this.setModalData(items);
      this.setState({
        isRedemptionsSearched: true,
        searchedRedemptionId: input,
      });
    }
  };

  handleOnClick = (id) => {
    const rowData = this.state.tableData.find((data) => data.id === id);
    const redemptionData = rowData.redemption;
    var total = 0;
    redemptionData.forEach((item) => {
      total = total + item.merchantEarning;
    });

    var redemptionPageCount = 0;
    var mod = rowData.redemption.length % 10;
    if (mod > 0) {
      redemptionPageCount = rowData.redemption.length / 10 - mod / 10 + 1;
    } else {
      redemptionPageCount = rowData.redemption.length / 10;
    }
    const modalData = {
      redemptions: rowData.redemption,
      slicedRedemptions: rowData.redemption.slice(0, 10),
      date: rowData.createdTimestamp,
      total: total,
      tableheading: {
        header: 'Redemptions',
        column: ['Redemption ID', 'Merchant Earning (LKR)'],
      },
      pageCount: redemptionPageCount,
      merchantId: rowData.coreMerchant.id,
      merchantName: rowData.coreMerchant.name,
    };

    this.handleModalShow(true);
    this.setState({
      onClickId: id,
      modalData,
    });
  };

  renderDrawerData = (data) => {
    return (
      <div className={styles.detail__group__nested}>
        <div className={styles.drawer_detail}>
          <div className={styles.drawer_detail_content}>
            <span className={styles.detail__value}>
              Date : {moment(data.date).format('DD/MM/YYYY hh:mm A')}
            </span>
          </div>
        </div>
        <div className={styles.drawer_detail}>
          <div className={styles.drawer_detail_content}>
            <span className={styles.detail__value}>
              Merchant ID : {data.merchantId}
            </span>
          </div>
          <div className={styles.drawer_detail_content}>
            <span className={styles.detail__value}>
              Merchant : {data.merchantName}
            </span>
          </div>
        </div>
        <div className={styles.drawer_detail}>
          <div className={styles.drawer_detail_content}>
            <span className={styles.detail__value}>
              {this.props.user.currentUser
                ? `Total Earned Amount : LKR ${
                    data.total &&
                    data.total
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }`
                : `Total amount : LKR ${
                    data.total &&
                    data.total
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }`}
            </span>
          </div>
        </div>
        <div className={styles.table_search}>
          <Search
            placeholder='Redemption ID'
            onBtnPress={this.handleRedemptionIdSearch}
            onClearPress={this.handleRedemptionSearchClear}
            max={10}
          />
        </div>
        <DataTable
          tableData={data.slicedRedemptions}
          tableheading={data.tableheading}
          handleOnClick={() => {}}
          tag='redemptions'
          fetchError=''
          searchFilterLoading={false}
          notFoundMessage={
            data.slicedRedemptions && data.slicedRedemptions.length === 0
              ? `No redemptions found for ${this.state.searchedRedemptionId}`
              : null
          }
        />
        {data.slicedRedemptions && data.slicedRedemptions.length > 10 && (
          <Pagination
            pageCount={data.pageCount}
            onChange={this.handleRedemptionPagination}
            currentPage={0}
          />
        )}
      </div>
    );
  };

  renderPaymentDetailDrawer = () => {
    return (
      <div className={styles.drawer__div}>
        <div className={styles.payment__header_content}>
          <h5 className={styles.payment__header}>PAYMENT ID : </h5>
          <h5 className={styles.payment__header_value}>
            {this.state.onClickId}
          </h5>
        </div>
        <div className={styles.drawer__child}>
          {this.renderDrawerData(this.state.modalData)}
        </div>
      </div>
    );
  };

  handlePagination = (value) => {
    const merchant = this.props.auth.details.merchant;

    if (this.state.startDate && this.state.endDate) {
      this.getMerchantPayablesByDate(
        this.state.startDate,
        this.state.endDate,
        value
      );
    } else if (merchant) {
      this.getMerchantPayables(value, merchant.id);
    } else {
      this.getAllMerchantsPayments(value);
    }
  };

  onChangeSearchKey = (event) => {
    this.setState({
      searchKey: event.target.value,
      startDate: null,
      endDate: null,
    });
  };

  handleSearch = async (event) => {
    try {
      event.preventDefault();
      const {
        searchKey,
        auth: { token },
      } = this.state;

      if (!searchKey) {
        if (this.state.selectedMerchantId) {
          this.getMerchantPayables(0, this.state.selectedMerchantId);
        } else {
          this.getAllMerchantsPayments(0);
        }
      } else {
        this.setState({
          searchFilterLoading: true,
        });

        const request = {
          merchantId: this.state.selectedMerchantId,
          referenceCode: searchKey,
        };

        const response = await searchMerchantPayableById(request, token);

        var data = [];
        data.push(response);
        this.setState({
          searchFilterLoading: false,
          tableData: data,
          canClearSearch: true,
          current: 0,
          total: 1,
          notFoundMessage:
            data.length === 0 ? `No payments found for ID ${searchKey}` : null,
        });
      }
    } catch (error) {
      this.setState({
        searchFilterLoading: false,
        fetchError: error,
        tableData: [],
        canClearSearch: true,
        current: 0,
        total: 1,
        notFoundMessage: `No payments found for ID ${this.state.searchKey}`,
      });
    }
  };

  handleOnClean = () => {
    this.setState({
      searchKey: null,
      canClearSearch: false,
      notFoundMessage: null,
    });
    if (this.state.selectedMerchantId) {
      this.getMerchantPayables(0, this.state.selectedMerchantId);
    } else {
      this.getAllMerchantsPayments(0);
    }
  };

  handleOnHideMerchantSelector = () => {
    this.setState({
      showMerchantDrawer: false,
      merchantPages: this.calculateMerchantpages(this.state.merchantList),
      searchedMerchantName: '',
      searchedMerchantId: '',
      slicedMerchantIds: this.state.merchantList.slice(0, 10),
      isMerchantSearched: false,
      paginateList: this.state.merchantList,
      startDate: null,
      endDate: null,
    });
  };

  onChangeStartDate = (startDate) => {
    const { endDate } = this.state;
    this.setState({
      startDate,
      searchKey: '',
    });
    if (startDate && endDate) {
      this.getMerchantPayablesByDate(startDate, endDate);
    }
    if (!startDate && !endDate) {
      if (this.state.selectedMerchantId) {
        this.getMerchantPayables(0, this.state.selectedMerchantId);
      } else {
        this.getAllMerchantsPayments(0);
      }
    }
  };

  onChangeEndDate = (endDate) => {
    const { startDate } = this.state;
    this.setState({
      endDate,
      searchKey: '',
    });
    if (startDate && endDate) {
      this.getMerchantPayablesByDate(startDate, endDate);
    }
    if (!startDate && !endDate) {
      if (this.state.selectedMerchantId) {
        this.getMerchantPayables(0, this.state.selectedMerchantId);
      } else {
        this.getAllMerchantsPayments(0);
      }
    }
  };

  getMerchantPayablesByDate = async (startDate, endDate, page = 0) => {
    try {
      this.setState({
        searchFilterLoading: true,
      });
      const request = {
        merchantId: this.state.selectedMerchantId,
        page: page,
        startDate: moment(startDate).startOf('day'),
        endDate: moment(endDate).endOf('day'),
      };

      const response = await filterMerchantPayables(
        request,
        this.state.auth.token
      );

      const sortList = sortBydate(response.coreMerchantPayable);
      this.setState({
        tableData: sortList,
        total: response.total,
        current: response.current,
        searchFilterLoading: false,
        notFoundMessage:
          sortList.length === 0 &&
          `No payments found for ${this.format(
            this.state.startDate
          )} - ${this.format(this.state.endDate)}`,
      });
    } catch (error) {
      this.setState({
        searchFilterLoading: false,
        fetchError: error,
      });
    }
  };

  format = (date) => {
    return moment(date).format('DD/MM/YYYY');
  };

  handleDownloadAsExcel = async () => {
    this.setState({ downloadStatus: 'DOWNLOADING' });
    const token = this.props.auth.token;
    const { startDate, endDate, selectedMerchantId, searchKey } = this.state;
    let response;
    try {
      if (startDate && endDate) {
        const req = {
          startDate: moment(startDate).startOf('day'),
          endDate: moment(endDate).endOf('day'),
        };
        response = await getAllMerchantPayableByDate(token, req);
      } else {
        response = await getAllMerchantPayable(token);
      }
      if (selectedMerchantId) {
        response = response.filter(
          ({ coreMerchant }) => coreMerchant.id === selectedMerchantId
        );
      }
      if (searchKey) {
        response = response.filter(
          ({ referenceCode }) => referenceCode === searchKey
        );
      }

      const excelData = response.map((data) => {
        let rowData = {
          Date: this.format(data.createdTimestamp),
          'Payment Id': data.id,
        };
        if (!this.props.auth.details.merchant) {
          rowData = {
            ...rowData,
            'Merchant Id': data.coreMerchant.id,
            'Merchant Name': data.coreMerchant.name,
            Amount: data.amount,
          };
        } else {
          rowData = {
            ...rowData,
            Amount: data.amount,
          };
        }
        return rowData;
      });
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, 'Merchant Payable.xlsx');
      this.setState({ downloadStatus: '' });
    } catch (e) {
      this.setState({ downloadStatus: 'ERROR' });
    }
  };

  renderMerchantOption = () => {
    return this.state.merchantShortList.map((merchant) => {
      return (
        <option value={merchant.name}>
          {merchant.name} ({merchant.id})
        </option>
      );
    });
  };

  renderDrawerMerchantOption = () => {
    var exist = false;
    this.state.merchantShortList.filter((merchant) => {
      if (this.state.selectedMerchant === merchant.name) {
        exist = true;
      }
    });

    if (!exist) {
      return <option>{this.state.selectedMerchant}</option>;
    }
  };

  render() {
    const { startDate, endDate, selectedMerchant } = this.state;
    const merchant = this.props.user.currentUser;
    return (
      <div className={styles.root__div}>
        {this.state.loading ? (
          <Loading text='Loading' />
        ) : (
          <div>
            <PageHeader text='Payments' />
            <div className={styles.search_area}>
              <div>
                <SearchByDate
                  onStartDateChange={this.onChangeStartDate}
                  onEndDateChange={this.onChangeEndDate}
                  startDate={startDate}
                  endDate={endDate}
                />
                <div className={styles.separator} />
                <SearchByRedemtionIdOrReferanceCode
                  onSubmit={this.handleSearch}
                  onChange={this.onChangeSearchKey}
                  value={this.state.searchKey}
                  placeholder='Reference Code'
                  onClean={this.handleOnClean}
                />
                <div className={styles.separator} />
                {!merchant && (
                  <Form.Control
                    as='select'
                    onChange={(e) =>
                      this.handleMerchantOnChange(e.target.value)
                    }
                    value={
                      this.state.selectedMerchant
                        ? this.state.selectedMerchant
                        : 'All Merchants'
                    }
                  >
                    <option>All Merchants</option>
                    {this.renderMerchantOption()}
                    {selectedMerchant &&
                      selectedMerchant !== 'All Merchants' &&
                      this.renderDrawerMerchantOption()}
                    <option className={styles.load_more}>Load More</option>
                  </Form.Control>
                )}
                {this.state.tableData.length > 0 && (
                  <div className={styles.download_btn}>
                    <Button
                      variant='primary'
                      onClick={this.handleDownloadAsExcel}
                    >
                      {(this.state.startDate && this.state.endDate) ||
                      this.state.searchKey ||
                      this.state.selectedMerchant
                        ? 'Download'
                        : 'Download All'}
                    </Button>
                    {this.state.downloadStatus === 'DOWNLOADING' && (
                      <p>Downloading as Excel...</p>
                    )}
                    {this.state.downloadStatus === 'ERROR' && (
                      <p className={styles.download_error_message}>
                        Oops, something went wrong. Please try again
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            <DataTable
              tableData={sortBydate(this.state.tableData)}
              tableheading={
                merchant
                  ? this.state.tableheadingForMerchant
                  : this.state.tableheading
              }
              handleOnClick={this.handleOnClick}
              tag='payments'
              fetchError={this.state.fetchError}
              search_filter_loading={this.state.searchFilterLoading}
              notFoundMessage={this.state.notFoundMessage}
              merchant={merchant}
            />
          </div>
        )}

        {this.state.total > 1 && (
          <div className={styles.pager}>
            <Pagination
              pageCount={this.state.total}
              onChange={this.handlePagination}
              currentPage={this.state.current}
            />
          </div>
        )}

        <Drawer
          show={this.state.showModal}
          onHide={() => this.handleModalShow(false)}
        >
          {this.renderPaymentDetailDrawer()}
        </Drawer>

        <Drawer
          show={this.state.showMerchantDrawer}
          onHide={this.handleOnHideMerchantSelector}
        >
          <MerchantSelector
            data={this.state.slicedMerchantIds}
            merchantId={this.state.selectedMerchantId}
            merchantPageCount={this.state.merchantPages}
            handleMerchantSearchById={this.handleMerchantIdSearch}
            handleMerchantSearchByName={this.handleMerchantNameSearch}
            handleMerchantPagination={this.handleMerchantpagination}
            handleMerchantIdOnChange={this.handleMerchantIdOnChange}
            handleMerchantsClear={this.handleMerchantsClear}
            searchedMerchantName={this.state.searchedMerchantName}
            searchedMerchantId={this.state.searchedMerchantId}
            paginateList={this.state.paginateList}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    user: state.user,
  };
};

export default connect(mapStateToProps)(ViewPayments);
