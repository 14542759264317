import React from 'react';

import styles from './DatePicker.module.scss';
import ReactDatePicker from 'react-datepicker';
import { ErrorMessage } from 'formik';

const DatePicker = (props) => {
  const {
    name,
    label,
    value,
    onChange,
    containerStyle,
    loading,
    ...rest
  } = props;

  const onDateChange = (date) => {
    onChange(date);
  };

  const DatePickerComponent = ({ value, onClick }) => (
    <button
      className={styles.picker__button}
      onClick={onClick}
      type='button'
      disabled={loading}
    >
      {value}
    </button>
  );

  return (
    <div className={styles.form__group} style={containerStyle}>
      <label className={styles.form__label}>{label}</label>
      <ReactDatePicker
        selected={value}
        onChange={onDateChange}
        {...rest}
        customInput={<DatePickerComponent />}
        dateFormat='dd/MM/yyyy'
      />
      <ErrorMessage name={name}>
        {(message) => <span className={styles.form__error}>{message}</span>}
      </ErrorMessage>
    </div>
  );
};

DatePicker.defaultProps = {
  maxDate: null,
  minDate: null,
  showTimeSelect: false,
  containerStyle: {},
  selectsStart: false,
  selectsEnd: false,
};

export default DatePicker;
