import React from 'react';
import ReactPaginate from 'react-paginate';

import './Pagination.scss';
import styles from './Pagination.module.scss';

const Pagination = (props) => {
  const { pageCount, onChange, currentPage } = props;

  const handleOnChange = ({ selected }) => {
    onChange(selected);
  };

  return (
    <div className={styles.pagination__root}>
      <ReactPaginate
        pageCount={pageCount}
        previousLabel='<'
        nextLabel='>'
        onPageChange={handleOnChange}
        forcePage={currentPage}
        containerClassName={'pagination'}
        pageClassName='page-item'
        previousClassName='page-item'
        nextClassName='page-item'
        pageLinkClassName='page-link'
        previousLinkClassName='page-link'
        nextLinkClassName='page-link'
        activeClassName='active'
      />
    </div>
  );
};

export default Pagination;
