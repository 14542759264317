import { combineReducers } from 'redux';

import { auth, dashboard, user } from '../reducers';

const root = combineReducers({
  auth,
  dashboard,
  user,
});

export default root;
