import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Card from 'react-bootstrap/Card';

import useComponentState from '../../utils/useComponentState';
import { Loading, PageHeader, EarningItem } from '../../components';
import { storeDashboardDetails } from '../../actions/dashboard';
import { getDashboard } from '../../api/subMerchant';
import { sortBydate } from '../../components/hoc/SortField';

import './SubMerchantDashboard.scss';

const SubMerchantDashboard = (props) => {
  const [state, setState] = useComponentState({
    dashboard: null,
    dashboardLoading: true,
    dashboardError: null,
    summaryContent: [
      { id: '1', header: 'Daily Earnings (LKR)', value: '0.00' },
      { id: '2', header: 'Monthly Earnings (LKR)', value: '0.00' },
      { id: '3', header: 'No. of Vouchers Redeemed', value: '0' },
    ],
  });

  const {
    auth: { token },
  } = props;
  const { dashboard, dashboardLoading, dashboardError, summaryContent } = state;
  const currentUser = props.user.currentUser;

  useEffect(() => {
    handleGetDashboardFromAPI();
    //eslint-disable-next-line
  }, []);

  const handleGetDashboardFromAPI = async () => {
    try {
      setState({ dashboardLoading: true, dashboardError: null });
      const response = await getDashboard(currentUser.id, token);

      setState({
        dashboardLoading: false,
        dashboard: { ...response },
        summaryContent: [
          {
            id: '1',
            header: 'Daily Earnings (LKR)',
            value: response.dailyEarnings,
          },
          {
            id: '2',
            header: 'Monthly Earnings (LKR)',
            value: response.monthlyEarnings,
          },
          {
            id: '3',
            header: 'No. of Vouchers Redeemed',
            value: response.numberOfVouchers,
          },
        ],
      });
      props.storeDashboardDetails(response);
    } catch (err) {
      setState({ dashboardLoading: false, dashboardError: err.message });
    }
  };

  const handleOnEarningsSeeMoreClick = () => {
    props.history.push('/earnings');
  };

  const renderLoading = () => {
    return <Loading text='Loading Dashboard' />;
  };

  const renderError = () => {
    return <div className='dashboard_error'>{dashboardError}</div>;
  };

  const renderSummary = () => {
    return summaryContent.map((item) => {
      const { id, header, value } = item;
      return (
        <Card id={id} className='submerchant_summary'>
          <Card.Body className='text-center'>
            <Card.Title>{header}</Card.Title>
            <Card.Text className='submerchant_summary__value'>
              {value === '.00' ? '0' : value}
            </Card.Text>
          </Card.Body>
        </Card>
      );
    });
  };

  const renderTableData = () => {
    const {
      earnings: { earnings },
    } = dashboard;
    sortBydate(earnings);
    return (
      <div className='submerchant_earnings__div'>
        <PageHeader text='Earnings' />
        <div className='table__header'>
          <span>Date (IST)</span>
          <span>Redemption Id</span>
          <span>Reference Code</span>
          <span>Status</span>
          <span>Earned Amount (LKR)</span>
        </div>
        {earnings.length === 0 ? (
          <div>
            <p className='redemption_error'>No redemptions</p>
          </div>
        ) : (
          earnings.map((earning) => {
            return <EarningItem earning={earning} />;
          })
        )}
        {earnings.length === 10 && (
          <div className='table__footer'>
            <span onClick={handleOnEarningsSeeMoreClick}>See More</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='submerchant_dashboard_root_div'>
      {dashboardLoading ? (
        renderLoading()
      ) : dashboardError ? (
        renderError()
      ) : (
        <div className='submerchant_dashboard_div'>
          <div className='submerchant_summary__root'>{renderSummary()}</div>
          <div className='submerchant_tables__div'>{renderTableData()}</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    dashboard: state.dashboard,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeDashboardDetails: (data) => {
      dispatch(storeDashboardDetails(data));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubMerchantDashboard)
);
