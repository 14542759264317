import createReducer from '../utils/createReducer';

import {
  SET_USERS,
  SET_CURRENT_USER,
  SET_CURRENT_USER_ROLE,
  SET_USER_DETAILS,
} from '../actions/user';

const initialState = {
  users: [],
  currentUser: null,
  role: null,
};

const setUsers = (state = initialState, action) => {
  return {
    ...state,
    users: action.payload,
  };
};

const setCurrentUser = (state = initialState, action) => {
  return {
    ...state,
    currentUser: action.payload,
  };
};

const setCurrentUserRole = (state = initialState, action) => {
  return {
    ...state,
    role: action.payload,
  };
};

const setUserDetails = (state = initialState, { payload }) => {
  return {
    ...state,
    ...payload,
  };
};

export default createReducer(initialState, {
  [SET_USERS]: setUsers,
  [SET_CURRENT_USER]: setCurrentUser,
  [SET_CURRENT_USER_ROLE]: setCurrentUserRole,
  [SET_USER_DETAILS]: setUserDetails,
});
