export const AUTH_TOKEN = 'AUTH_TOKEN';

export const set = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const retrieve = (key) => {
  const val = localStorage.getItem(key);

  if (val && val !== '') {
    return JSON.parse(val);
  }

  return null;
};

export const remove = (key) => {
  localStorage.removeItem(key);
};
