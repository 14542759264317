import React from 'react';
import { connect } from 'react-redux';

import Card from 'react-bootstrap/Card';

import logo from '../../styles/images/login_logo_high_res.png';
import instagram from '../../styles/images/instagram.jpg';
import facebook from '../../styles/images/facebook.png';
import './WelcomeScreen.scss';

const WelcomeScreen = (props) => {
  return (
    <div className='welcome_root'>
      <Card className='text-center p-3'>
        <blockquote className='blockquote mb-0 card-body'>
          <Card.Img variant='top' src={logo} className='card_img' />
          <p>Welcome to thyaga admin portal</p>

          <footer className='blockquote-footer'>
            <small className='text-muted'>
              Logged in with{' '}
              <cite title='Source Title'>{props.auth.details.email}</cite>
            </small>

            <Card.Body className='social_root'>
              <div className='social_logo_root'>
                <Card.Img
                  variant='top'
                  src={instagram}
                  className='social_logo'
                />
                <small>www.instagram.com/thyaga.lk</small>
              </div>
              <div className='social_logo_root'>
                <Card.Img
                  variant='top'
                  src={facebook}
                  className='social_logo'
                />
                <small>www.facebook.com/thyaga.lk</small>
              </div>
            </Card.Body>
          </footer>
        </blockquote>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps)(WelcomeScreen);
