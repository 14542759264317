import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Drawer } from 'rsuite';

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';

import DataTable from '../../components/DataTable/DataTable';
import { PageHeader, Pagination } from '../../components';
import { getAllMerchant } from '../../api/merchant';
import { getSubMerchantsByMerchant } from '../../api/subMerchant';
import { addNewSubmerchant } from '../../api/subMerchant';
import Search from '../../components/Search/Search.component';

import './ViewMerchants.scss';

class ViewMerchants extends Component {
  state = {
    loading: true,
    tableData: [],
    tableheading: {
      header: 'MERCHANTS',
      column: [
        'ID',
        'Name',
        'Category',
        'Status',
        'Website',
        'Contact',
        'Voucher Count',
      ],
    },
    fetchError: '',
    slicedData: [],
    merchantPages: 0,
    total: 1,
    mainCurrent: 0,
    current: 0,
    merchantDrawer: false,
    submerchantsData: [],
    subMerchantTableheading: {
      header: 'SUB MERCHANTS',
      column: ['ID', 'Email', 'Location'],
    },
    subMerchantFetchError: '',
    selectedMerchant: null,
    selectedMerchantId: null,
    showModal: false,
    email: '',
    location: '',
    merchantPin: '',
    validated: false,
    addError: null,
    addIsSuccess: false,
    addApiCalling: false,
    isMerchantSearched: false,
    searchedMerchant: [],
    searchedMerchantMessage: null,
    pinError: null,
    pinType: 'password',
    emailError: null,
    slicedSubMerchants: [],
    subMerchantPage: 0,
    successMessage: null,
  };

  componentWillMount() {
    getAllMerchant(this.props.auth.token)
      .then((response) => {
        var merchantPage = 0;
        var mod = response.length % 10;
        if (mod > 0) {
          merchantPage = response.length / 10 - mod / 10 + 1;
        } else {
          merchantPage = response.length / 10;
        }
        this.setState({
          tableData: this.sortMerchantList(response),
          loading: false,
          merchantPages: merchantPage,
          slicedData: response.slice(0, 10),
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  parseInt = (id) => {
    return parseInt(id.substring(0), 10) + 1;
  };

  sortMerchantList = (data) => {
    return data.sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));
  };

  handleMerchantNameSearch = (name) => {
    if (name) {
      const item = this.state.tableData.filter((item) => {
        let itemData = item.name.toLowerCase();
        return itemData.indexOf(name.toLowerCase()) > -1;
      });

      this.setState({
        searchedMerchant: item,
        isMerchantSearched: true,
        searchedMerchantMessage: `found for name ${name}`,
      });
    }
  };

  handleMerchantSearch = (id) => {
    if (id) {
      const item = this.state.tableData.filter((item) => item.id === id);

      this.setState({
        searchedMerchant: item,
        isMerchantSearched: true,
        searchedMerchantMessage: `id found for ${id}`,
      });
    }
  };

  handlMerchantsclear = () => {
    this.setState({
      searchedMerchant: [],
      isMerchantSearched: false,
      searchedMerchantMessage: null,
    });
  };

  handleTextVisibility = () => {
    if (this.state.pinType === 'password') {
      this.setState({ pinType: 'text' });
    } else {
      this.setState({ pinType: 'password' });
    }
  };

  handleMerchantpagination = (page) => {
    if (page > 0) {
      const sliceStart = page * 10;
      const sliceEnd = (page + 1) * 10;
      this.setState({
        slicedData: this.state.tableData.slice(sliceStart, sliceEnd),
      });
    } else {
      const sliceStart = 0;
      const sliceEnd = 10;
      this.setState({
        slicedData: this.state.tableData.slice(sliceStart, sliceEnd),
      });
    }
  };

  handleSubMerchantPagination = (page) => {
    let sliceStart = 0;
    let sliceEnd = 10;
    if (page > 0) {
      sliceStart = page * 10;
      sliceEnd = (page + 1) * 10;
    }
    this.setState({
      slicedSubMerchants: this.state.submerchantsData.slice(
        sliceStart,
        sliceEnd
      ),
    });
  };

  handleOnClick = (id) => {
    getSubMerchantsByMerchant(id, this.props.auth.token)
      .then((response) => {
        var submerchantPage = 0;
        var mod = response.subMerchants.length % 10;
        if (mod > 0) {
          submerchantPage = response.subMerchants.length / 10 - mod / 10 + 1;
        } else {
          submerchantPage = response.subMerchants.length / 10;
        }
        this.setState({
          submerchantsData: response.subMerchants,
          merchantDrawer: true,
          selectedMerchant: this.getSelectedMerchant(id),
          selectedMerchantId: id,
          slicedSubMerchants: response.subMerchants.slice(0, 10),
          subMerchantPage: submerchantPage,
        });
      })
      .catch((err) => {
        this.setState({
          submerchantsData: [],
          merchantDrawer: true,
          selectedMerchant: this.getSelectedMerchant(id),
          slicedSubMerchants: [],
        });
      });
  };

  getSelectedMerchant = (id) => {
    var name = '';
    this.state.tableData.filter((data) => {
      if (data.id === id) {
        name = data.name;
      }
    });
    return name;
  };

  handleDrawShow = (boolean) => {
    this.setState({
      merchantDrawer: boolean,
    });
  };

  handleAddNewSubMerchantOnClick = () => {
    this.handleDrawShow(false);
    this.handleModalShow(true);
  };

  handleModalShow = (boolean) => {
    this.setState({
      showModal: boolean,
    });
    if (!boolean) {
      this.setState({
        email: '',
        location: '',
        merchantPin: '',
        addIsSuccess: false,
        validated: false,
        pinError: null,
        emailError: null,
      });
    }
  };

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: this.state[name].length === 0 ? value.trim() : value,
      emailError: null,
    });
  };

  handlePinOnChange = (event) => {
    this.setState({
      merchantPin: event.target.value.replace(/[^0-9\s]/g, '').trim(),
      pinError: null,
    });
  };

  validatedEmail = () => {
    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(this.state.email);

    return result;
  };

  handleAddNewSubMerchant = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({
      validated: true,
      addApiCalling: true,
    });

    if (this.state.merchantPin.length === 0) {
      this.setState({
        pinError: 'Merchant pin cannot be empty',
        addApiCalling: false,
      });
    } else {
      if (!this.validatedEmail()) {
        this.setState({
          emailError: 'Enter a valid email address',
          addApiCalling: false,
        });
        return;
      }
      const request = {
        email: this.state.email,
        location: this.state.location,
        mangerPin: this.state.merchantPin,
        merchantId: this.state.selectedMerchantId,
      };
      try {
        const result = await addNewSubmerchant(request, this.props.auth.token);
        this.setState({
          addIsSuccess: true,
          addApiCalling: false,
          successMessage: result.message,
        });
      } catch (err) {
        this.setState({
          addError: 'Failed to add new subMerchant',
          addIsSuccess: false,
          addApiCalling: false,
        });
      }
    }
  };

  renderInputField = (name, label, value, onChange, max) => {
    return (
      <Form.Group as={Row}>
        <Col>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            name={name}
            value={value}
            onChange={onChange}
            maxLength={max}
            required
            autoComplete='off'
          />
          <Form.Control.Feedback type='invalid' className='error_msg'>
            {label} cannot be empty
          </Form.Control.Feedback>
          {name === 'email' && this.state.emailError && (
            <small className='error_msg'>{this.state.emailError}</small>
          )}
        </Col>
      </Form.Group>
    );
  };

  renderInputGroup = (name, label, value, onChange, max, onClick, type) => {
    return (
      <Form.Group as={Row}>
        <Col>
          <Form.Label>{label}</Form.Label>
          <InputGroup>
            <Form.Control
              type={type}
              name={name}
              value={value}
              onChange={onChange}
              required
              maxLength={max}
            />
            <InputGroup.Append onClick={onClick}>
              <InputGroup.Text className='password-toggle__span'>
                <i
                  className={type === 'text' ? 'fa fa-eye-slash' : 'fa fa-eye'}
                ></i>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          {this.state.pinError && (
            <small className='error_msg'>{this.state.pinError}</small>
          )}
        </Col>
      </Form.Group>
    );
  };

  renderSubMerchantsDrawer = () => {
    return (
      <div className='drawer_table'>
        <div className='drawer_search_area'>
          <span className='drawer_page_header'>
            Sub Merchants For {this.state.selectedMerchant}
          </span>
        </div>
        <div
          className={
            this.state.submerchantsData.length > 10
              ? 'drawer_table_content_page'
              : 'drawer_table_content'
          }
        >
          <DataTable
            tableData={this.state.slicedSubMerchants}
            tableheading={this.state.subMerchantTableheading}
            handleOnClick={() => {}}
            tag='subMerchants'
            fetchError={this.state.subMerchantFetchError}
          />
        </div>
        {this.state.submerchantsData.length > 10 && (
          <Pagination
            pageCount={this.state.subMerchantPage}
            onChange={this.handleSubMerchantPagination}
            currentPage={0}
          />
        )}
        <div className='drawer_addBtn'>
          <Button
            variant='dark'
            block
            onClick={this.handleAddNewSubMerchantOnClick}
          >
            Add New Sub Merchant
          </Button>
        </div>
      </div>
    );
  };

  renderAddNewSubMerchant = () => {
    return (
      <Modal
        show={this.state.showModal}
        onHide={() => {
          this.handleModalShow(false);
        }}
        backdrop='static'
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Add New Sub Merchant for ${this.state.selectedMerchant}`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleAddNewSubMerchant}
          >
            {this.renderInputField(
              'email',
              'Email',
              this.state.email,
              this.handleOnChange,
              320
            )}
            {this.renderInputField(
              'location',
              'Location',
              this.state.location,
              this.handleOnChange,
              255
            )}
            {this.renderInputGroup(
              'merchantPin',
              'Merchant Pin',
              this.state.merchantPin,
              this.handlePinOnChange,
              5,
              this.handleTextVisibility,
              this.state.pinType
            )}
            <Button variant='dark' type='submit' block>
              {this.state.addApiCalling ? 'Loading' : 'ADD'}
            </Button>
          </Form>
        </Modal.Body>
        <div className='successMessage'>
          {this.state.addIsSuccess && (
            <span className='success_msg'>{this.state.successMessage}</span>
          )}
        </div>
      </Modal>
    );
  };

  render() {
    return (
      <div className='root__div'>
        {this.state.loading ? (
          <div className='spinner'>
            <Spinner animation='border' />
          </div>
        ) : (
          <div className='table'>
            <div className='search_area'>
              <PageHeader text='Merchants' />
              <div className='table_search'>
                <Search
                  placeholder='Merchant Name'
                  onBtnPress={this.handleMerchantNameSearch}
                  onClearPress={this.handlMerchantsclear}
                  max={10}
                />
                <Search
                  placeholder='Merchant ID'
                  onBtnPress={this.handleMerchantSearch}
                  onClearPress={this.handlMerchantsclear}
                  max={50}
                />
              </div>
            </div>
            <DataTable
              tableData={
                this.state.isMerchantSearched
                  ? this.state.searchedMerchant
                  : this.state.slicedData
              }
              tableheading={this.state.tableheading}
              handleOnClick={this.handleOnClick}
              tag='merchants'
              fetchError={this.state.fetchError}
              notFoundMessage={
                this.state.isMerchantSearched &&
                this.state.searchedMerchant.length === 0 &&
                `No merchant ${this.state.searchedMerchantMessage}`
              }
            />
            {!this.state.isMerchantSearched && (
              <Pagination
                pageCount={this.state.merchantPages}
                onChange={this.handleMerchantpagination}
                currentPage={0}
              />
            )}
          </div>
        )}

        <Drawer
          show={this.state.merchantDrawer}
          onHide={() => this.handleDrawShow(false)}
        >
          {this.renderSubMerchantsDrawer()}
        </Drawer>

        {this.renderAddNewSubMerchant()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(ViewMerchants);
