import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useComponentState from '../../utils/useComponentState';

const Search = ({
  placeholder,
  onBtnPress,
  onClearPress,
  max,
  onChangeKey,
  value,
  onChange,
  handleValueFromParent,
}) => {
  const [state, setState] = useComponentState({
    input: '',
    isApiCalled: false,
  });

  const handleOnSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (handleValueFromParent) {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (!state.isApiCalled) {
        setState({
          isApiCalled: true,
        });
        onBtnPress(value);
      } else {
        setState({
          isApiCalled: false,
        });
        onChange('');
        onClearPress();
      }
    } else {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (!state.isApiCalled) {
        setState({
          isApiCalled: true,
        });
        onBtnPress(state.input);
      } else {
        setState({
          isApiCalled: false,
          input: '',
        });
        onClearPress();
      }
    }
  };

  const handleInputOnChange = (event) => {
    if (handleValueFromParent) {
      setState({
        isApiCalled: false,
      });
      onChange(event.target.value);
      onChangeKey && onChangeKey(event.target.value);
    } else {
      setState({
        input: event.target.value,
        isApiCalled: false,
      });
      onChangeKey && onChangeKey(event.target.value);
    }
  };

  return (
    <div>
      <Form noValidate onSubmit={handleOnSubmit}>
        <InputGroup className='mb-3'>
          <Form.Control
            placeholder={placeholder}
            aria-label={placeholder}
            value={handleValueFromParent ? value : state.input}
            onChange={handleInputOnChange}
            required
            maxLength={max}
          />
          <InputGroup.Append>
            <Button variant='dark' type='submit'>
              {state.isApiCalled && value !== '' ? (
                <FontAwesomeIcon icon={faTimes} />
              ) : (
                <FontAwesomeIcon icon={faSearch} />
              )}
            </Button>
          </InputGroup.Append>
          <Form.Control.Feedback type='invalid'>
            {placeholder} Cannot be Empty
          </Form.Control.Feedback>
        </InputGroup>
      </Form>
    </div>
  );
};

export default Search;
