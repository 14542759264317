import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import Loading from './../loading/Loading.component';

import useComponentState from './../../utils/useComponentState';

const Filter = (props) => {
  const {
    title,
    userDetailsFilter,
    filterData,
    filterLoading,
    submitFilterDate,
    handleExportDate,
  } = props;
  const [state, setState] = useComponentState({
    show: false,
    acceptPromotion: false,
    userTimeZone: null,
    emailAddress: null,
  });
  const { show, acceptPromotion, userTimeZone, emailAddress } = state;

  const handleShow = () => {
    setState({ show: true });
  };
  const handleClose = () => {
    setState({
      show: false,
      acceptPromotion: false,
      userTimeZone: null,
      emailAddress: null,
    });
  };
  const handleReset = () => {
    setState({
      acceptPromotion: false,
      userTimeZone: null,
      emailAddress: null,
    });
  };

  const handleFilter = async () => {
    const data = {
      acceptPromotion,
      timeZone: userTimeZone,
      email: emailAddress,
    };
    submitFilterDate(data);
  };

  const handleAcceptPromotionChange = () => {
    setState({ acceptPromotion: !state.acceptPromotion });
  };
  const handleTimeZoneChange = (data) => {
    setState({ userTimeZone: data.target.value });
  };
  const handleEmailChange = (data) => {
    setState({ emailAddress: data.target.value });
  };
  return (
    <div>
      <Button variant='primary' onClick={handleShow}>
        Filters
      </Button>
      <Modal style={{ opacity: 1 }} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title} Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {userDetailsFilter && (
              <div>
                <Form.Group controlId='formBasicCheckbox'>
                  <Form.Check
                    checked={acceptPromotion}
                    onChange={handleAcceptPromotionChange}
                    type='checkbox'
                    label='Accept Promotion'
                  />
                </Form.Group>
                <hr />
                <Form.Group controlId='exampleForm.SelectCustom'>
                  <Form.Label>User Time Zone</Form.Label>
                  <Form.Control
                    onChange={handleTimeZoneChange}
                    as='select'
                    custom
                  >
                    <option value=''>All</option>
                    <option>Asia/Colombo</option>
                  </Form.Control>
                </Form.Group>
                <hr />
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    value={emailAddress}
                    onChange={handleEmailChange}
                    type='email'
                    placeholder='Enter email'
                  />
                </Form.Group>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {filterLoading ? (
            <Loading />
          ) : (
            filterData.length > 0 && (
              <Button
                variant='secondary'
                onClick={() => handleExportDate(filterData)}
              >
                Export
              </Button>
            )
          )}

          <Button variant='secondary' onClick={handleReset}>
            Reset
          </Button>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleFilter}>
            Filter
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(Filter);
