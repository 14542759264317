import React from 'react';
import { Form, Col } from 'react-bootstrap';

import styles from './SearchRedemptionDrodown.module.scss';

const SearchRedemptionDrodown = (props) => {
  const { onSelectChange } = props;
  return (
    <div className={styles.root__div}>
      <div>
        <Form.Row>
          <Col>
            <Form.Control
              className={styles.tsubmerchant_picker__control}
              as='select'
              onChange={(e) => onSelectChange(e.target.value)}
            >
              <option value='REDEMPTION_ID'>Redemption ID</option>
              <option value='REFERENCE_CODE'>Reference Code</option>
            </Form.Control>
          </Col>
        </Form.Row>
      </div>
    </div>
  );
};
export default SearchRedemptionDrodown;
