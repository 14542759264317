import { POST, GET } from './core';

export const getEndpointWithPrefix = (endpoint) => {
  return `/offers/${endpoint}`;
};

export const createOffer = (offer, token) => {
  const endpoint = getEndpointWithPrefix(`create-offer `);
  const body = {
    ...offer,
  };

  return POST(endpoint, body, token);
};

export const getOffers = (merchantId, page = 0, token) => {
  const endpoint = getEndpointWithPrefix(`${merchantId}?page=${page}`);

  return GET(endpoint, token);
};

export const getAllOffers = (page = 0, token) => {
  const endpoint = getEndpointWithPrefix(`all/${page}`);

  return GET(endpoint, token);
};

export const getOffersByMerchantName = (request, token) => {
  const endpoint = getEndpointWithPrefix('getOffersByMerchantName');
  return POST(endpoint, request, token);
};

export const getOffersByDates = (request, token) => {
  const endpoint = getEndpointWithPrefix('getOffersByDates');
  return POST(endpoint, request, token);
};

export const updateOffer = (request, token) => {
  const endpoint = getEndpointWithPrefix('updateOffer');
  return POST(endpoint, request, token);
};

export const getOffersByName = (request, token) => {
  const endpoint = getEndpointWithPrefix('getByName');
  return POST(endpoint, request, token);
};
