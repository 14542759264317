import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Loading, AppInput, AppButton } from '../../components';

import styles from './UnredeemPopup.module.scss';

const UnredeemPopup = (props) => {
  const {
    show,
    onChangeValue,
    onSubmit,
    password,
    loading,
    error,
    onCancel,
  } = props;

  const renderLoading = () => {
    return (
      <div className={styles.loading}>
        <Loading text='Loading' />
      </div>
    );
  };

  return (
    <Modal size='md' centered show={show} animation={false}>
      {loading ? (
        renderLoading()
      ) : (
        <Formik
          initialValues={{
            password: password,
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required('Password is required')
              .max(30, 'password too Long'),
          })}
          onSubmit={onSubmit}
        >
          <Form onChange={onChangeValue} className={styles.form_div}>
            <AppInput
              name='password'
              label='Enter password to confirm'
              type='password'
              placeholder='Password'
              loading={loading}
              maxLength='30'
              password
            />
            <div className={styles.button_group}>
              <div className={styles.button}>
                <AppButton text='Unredeem' type='submit' loading={loading} />
              </div>
              <div className={styles.button}>
                <AppButton
                  text='Cancel'
                  type='button'
                  loading={loading}
                  onClick={onCancel}
                />
              </div>
            </div>
            {error && <span className={styles.form__error}>{error}</span>}
          </Form>
        </Formik>
      )}
    </Modal>
  );
};
export default UnredeemPopup;
