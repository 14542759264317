import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { login } from '../../api/auth';
import { authSuccess } from '../../actions/auth';
import {
  setCurrentUser,
  setUsers,
  setCurrentUserRole,
} from '../../actions/user';
import { Loading, AppInput, AppButton } from '../../components';
import useComponentState from '../../utils/useComponentState';

import logo from '../../styles/images/login_logo_high_res.png';
import styles from './Login.module.scss';

const Login = (props) => {
  const [state, setState] = useComponentState({
    loading: false,
    error: null,
  });

  const { loading, error } = state;
  const { loginSuccess, setCurrentUser, setUsers, setCurrentUserRole } = props;

  const handleOnLogin = async (values) => {
    try {
      setState({ loading: true, error: null });
      const authData = {
        ...values,
      };
      const response = await login(authData);
      handleSetUser(response.details);
      loginSuccess(response);
    } catch (err) {
      setState({
        loading: false,
        error:
          err.message === 'Bad credentials'
            ? 'Invalid credentials. Please try again'
            : err.message,
      });
    }
  };

  const handleSetUser = (details) => {
    const merchantAdmins = details.merchantDetailsControllerResponses;
    const subMerchantAdmin = details.coreSubMerchantAdmin;
    let users = [];
    let currentUser = null;

    if (merchantAdmins) {
      users = merchantAdmins;
      currentUser = merchantAdmins[0].merchant;
    } else if (subMerchantAdmin) {
      users = subMerchantAdmin;
      currentUser = subMerchantAdmin;
    }
    setUsers(users);
    setCurrentUser(currentUser);
    setCurrentUserRole(details.role);
  };

  const renderLoading = () => {
    return <Loading text='Logging in' />;
  };

  const onChangeValue = (s) => {
    setState({ error: null });
  };

  return (
    <div className={styles.root__div}>
      <div className={styles.child__div}>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required('Email is required')
              .email('Please enter a valid email address')
              .max(320, 'Email too Long'),
            password: Yup.string()
              .required('Password is required')
              .max(30, 'password too Long'),
          })}
          onSubmit={handleOnLogin}
        >
          {({ values }) => {
            return loading ? (
              renderLoading()
            ) : (
              <Form onChange={onChangeValue}>
                <div className={styles.logo__div}>
                  <img src={logo} className={styles.logo__img} alt='logo' />
                </div>
                <span className={styles.welcome__msg}>
                  Hello there!, Welcome to Thyāga Merchant Portal.
                  <br /> Please login to continue.
                </span>
                <AppInput
                  name='email'
                  label='Email Address'
                  type='text'
                  placeholder='someone@example.com'
                  loading={loading}
                  maxLength='320'
                />
                <AppInput
                  name='password'
                  label='Password'
                  type='text'
                  placeholder='*******'
                  loading={loading}
                  maxLength='30'
                  password
                />
                <AppButton text='Login' type='submit' loading={loading} />
                {error && <span className={styles.form__error}>{error}</span>}
                <div className={styles.forgotpassword_div}>
                  <Link to={`/forgotpassword`}>Forgot password?</Link>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginSuccess: (data) => {
      dispatch(authSuccess(data));
    },
    setCurrentUser: (data) => {
      dispatch(setCurrentUser(data));
    },
    setUsers: (data) => {
      dispatch(setUsers(data));
    },
    setCurrentUserRole: (data) => {
      dispatch(setCurrentUserRole(data));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
