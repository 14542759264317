import React, { Component } from 'react';

import styles from './AddTerminal.module.scss';

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';

import { saveTerminal } from '../../api/Terminal';
import { getAllMerchant } from '../../api/merchant';
import { getSubMerchantsByMerchant } from '../../api/subMerchant';
import { connect } from 'react-redux';

class AddTerminal extends Component {
  state = {
    description: '',
    merchants: [],
    selectMerchant: '',
    submerchants: [],
    selectSubMerchant: '',
    model: '',
    pin: '',
    status: 'Active',
    type: 'ONLINE',
    validated: false,
    apiStatus: false,
    isApiCalled: false,
    loading: false,
    error: null,
    typeData: [
      { id: '1', name: 'Online' },
      { id: '2', name: 'Plugin' },
      { id: '3', name: 'Store' },
    ],
    statusData: [
      { id: '1', name: 'Active' },
      { id: '2', name: 'Inactive' },
    ],
    apiResponse: '',
    merchantError: null,
    subMerchantError: null,
  };

  componentWillMount() {
    this.getAllMerchantByApi();
  }
  getAllMerchantByApi = async () => {
    this.setState({ loading: true });
    try {
      const response = await getAllMerchant(this.props.auth.auth.token);
      const sortList = this.sortMerchantList(response);
      this.setState({
        merchants: sortList,
        selectMerchant: sortList[0] ? sortList[0].id : '',
        loading: false,
      });
      this.getsubMerchantForMerchantByApi(sortList[0].id);
    } catch (error) {
      this.setState({
        merchants: [],
        selectMerchant: '',
        loading: false,
      });
    }
  };

  sortMerchantList = (data) => {
    return data.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
  };

  sortSubMerchantList = (data) => {
    return data.sort((a, b) =>
      a.location.toLowerCase() > b.location.toLowerCase() ? 1 : -1
    );
  };

  getsubMerchantForMerchantByApi = async (merchantId) => {
    this.setState({ loading: true });
    try {
      const response = await getSubMerchantsByMerchant(
        merchantId,
        this.props.auth.auth.token
      );
      const { subMerchants } = response;
      const sortList = this.sortSubMerchantList(subMerchants);
      this.setState({
        subMerchants: sortList,
        selectSubMerchant: sortList[0] ? sortList[0].id : '',
        loading: false,
      });
    } catch (error) {
      this.setState({
        subMerchants: [],
        selectSubMerchant: '',
        loading: false,
      });
    }
  };
  handleErrorCodes = (error) => {
    switch (error.message) {
      case 'User is not a merchant.':
        this.handleSetError('User is not a merchant');
        break;
      case 'Sub Merchant Not Found':
        this.handleSetError('Invalid request. Please try again');
        break;
      default:
        this.handleSetError(error.message);
    }
  };

  handleSetError = (err) => {
    this.setState({
      error: err,
    });
  };

  handleInputOnChange = (e) => {
    this.setState({
      [e.target.name]:
        e.target.name === 'type'
          ? e.target.value.toUpperCase()
          : e.target.value,
      isApiCalled: false,
    });
  };

  handlePinOnChange = (e) => {
    this.setState({
      pin: e.target.value.replace(/[^0-9\s]/g, ''),
      isApiCalled: false,
    });
  };

  handleMerchantChange = (data) => {
    this.setState({
      selectMerchant: data.target.value,
      submerchants: [],
      selectSubMerchant: '',
      isApiCalled: false,
      merchantError: null,
      subMerchantError: null,
    });
    this.getsubMerchantForMerchantByApi(data.target.value);
  };

  handlesubMerchantChange = (data) => {
    this.setState({
      selectSubMerchant: data.target.value,
      isApiCalled: false,
      subMerchantError: null,
    });
  };

  handleOnSubmit = async (event) => {
    const {
      auth: { token },
    } = this.props.auth;
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    const {
      description,
      selectSubMerchant,
      selectMerchant,
      model,
      type,
      pin,
      status,
    } = this.state;

    this.setState({
      description: description && description.trim(),
      model: model && model.trim(),
      pin: pin.trim(),
      status: status.trim(),
      validated: true,
    });
    const terminal = {
      description,
      submerchant: selectSubMerchant,
      merchant: selectMerchant,
      model,
      type,
      pin,
      status,
    };

    if (!selectMerchant) {
      this.setState({ merchantError: 'Merchant cannot be empty' });
      return;
    }

    if (!selectSubMerchant) {
      this.setState({ subMerchantError: 'Submerchant cannot be empty' });
      return;
    }

    if (pin.trim() !== '') {
      this.setState({
        isApiCalled: true,
        loading: true,
      });
      try {
        const response = await saveTerminal(terminal, token);
        this.setState({
          apiStatus: true,
          loading: false,
          apiResponse: response.message,
        });
      } catch (error) {
        this.setState({
          apiStatus: false,
          loading: false,
        });
        this.handleErrorCodes(error);
      }
    }
  };

  renderTextArea = (label, name, value, maxLength, placeholder, onChange) => {
    return (
      <Form.Group controlId='Customvalidation' as={Row}>
        <Form.Label column sm={2}>
          {label}
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            as='textarea'
            rows='3'
            name={name}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            placeholder={placeholder}
            size='sm'
            required={name !== 'description'}
          />
          <Form.Control.Feedback type='invalid'>
            <span className={styles.errMsg}>{label} cannot be empty</span>
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    );
  };

  renderInputField = (
    label,
    type,
    name,
    value,
    maxLength,
    placeholder,
    onChange
  ) => {
    return (
      <Form.Group controlId='Customvalidation' as={Row}>
        <Form.Label column sm={2}>
          {label}
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            required={name !== 'model'}
            placeholder={placeholder}
            size='sm'
          />
          <Form.Control.Feedback type='invalid'>
            <span className={styles.errMsg}>{label} cannot be empty</span>
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    );
  };

  renderDropDown = (label, name, value, data, onChange) => {
    return (
      <Form.Group controlId='Customvalidation' as={Row}>
        <Form.Label column sm={2}>
          {label}
        </Form.Label>
        <Col sm={10}>
          <select
            id='x-select'
            class='form-control form-control-sm custom-select'
            onClick={onChange}
            name={name}
            required
          >
            {name === 'submerchant'
              ? data &&
                data.map((item) => (
                  <option key={item.id} value={item.id}>
                    {' '}
                    {item.location} ( {item.id} ){' '}
                  </option>
                ))
              : name === 'merchant'
              ? data &&
                data.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))
              : data &&
                data.map((item) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
          </select>
          {name === 'merchant' && this.state.merchantError ? (
            <small className={styles.errMsg}>{this.state.merchantError}</small>
          ) : name === 'submerchant' && this.state.subMerchantError ? (
            <small className={styles.errMsg}>
              {this.state.subMerchantError}
            </small>
          ) : null}
        </Col>
      </Form.Group>
    );
  };

  renderDropDown = (label, name, value, data, onChange) => {
    return (
      <Form.Group controlId='Customvalidation' as={Row}>
        <Form.Label column sm={2}>
          {label}
        </Form.Label>
        <Col sm={10}>
          <select
            id='x-select'
            class='form-control form-control-sm custom-select'
            onClick={onChange}
            name={name}
            required
          >
            {name === 'submerchant'
              ? data &&
                data.map((item) => (
                  <option key={item.id} value={item.id}>
                    {' '}
                    {item.location} ( {item.id} ){' '}
                  </option>
                ))
              : name === 'merchant'
              ? data &&
                data.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))
              : data &&
                data.map((item) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
          </select>
          {name === 'merchant' && this.state.merchantError ? (
            <small className={styles.errMsg}>{this.state.merchantError}</small>
          ) : name === 'submerchant' && this.state.subMerchantError ? (
            <small className={styles.errMsg}>
              {this.state.subMerchantError}
            </small>
          ) : null}
        </Col>
      </Form.Group>
    );
  };

  renderFormField = () => {
    return (
      <div className={styles.form_root}>
        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.handleOnSubmit}
        >
          {this.renderDropDown(
            'Merchant',
            'merchant',
            this.state.selectMerchant,
            this.state.merchants,
            this.handleMerchantChange
          )}

          {this.renderDropDown(
            'Sub Merchant',
            'submerchant',
            this.state.selectSubMerchant,
            this.state.subMerchants,
            this.handlesubMerchantChange
          )}

          {this.renderDropDown(
            'Type',
            'type',
            this.state.type,
            this.state.typeData,
            this.handleInputOnChange
          )}

          {this.renderTextArea(
            'Description',
            'description',
            this.state.description,
            255,
            'EX: Terminal 1',
            this.handleInputOnChange
          )}

          {this.renderInputField(
            'Model',
            'text',
            'model',
            this.state.model,
            10,
            'EX: M001',
            this.handleInputOnChange
          )}

          {this.renderInputField(
            'Pin',
            'text',
            'pin',
            this.state.pin,
            5,
            'EX: 12345',
            this.handlePinOnChange
          )}

          {this.renderDropDown(
            'Status',
            'status',
            this.state.status,
            this.state.statusData,
            this.handleInputOnChange
          )}

          <Button
            variant='primary'
            type='submit'
            className={styles.submitBtn}
            disabled={this.state.isApiCalled}
          >
            Save Terminal
          </Button>
        </Form>
        {this.state.isApiCalled ? (
          <div className={styles.apiStatus}>
            {this.state.loading ? (
              <Spinner animation='border' />
            ) : this.state.apiStatus ? (
              <p className={styles.successMsg}>{this.state.apiResponse}</p>
            ) : (
              <p className={styles.errMsg}>Failed to add Terminal</p>
            )}
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <div className={styles.root__div}>
        <Table bordered>
          <thead className={styles.tHead}>
            <tr>
              <th>ADD TERMINAL</th>
            </tr>
          </thead>
          {this.renderFormField()}
        </Table>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTerminal);
