import React from 'react';
import { Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { AppInput } from '../../components';

import styles from './ForgotPassword.module.scss';

const VerifyKey = (props) => {
  const {
    onSubmit,
    onChange,
    onResendButtonPress,
    value,
    error,
    email,
    handleGoBack,
  } = props;
  return (
    <div>
      <div className={styles.forgotpw__sub_title}>
        Reset code has been sent to {email}
      </div>
      <Formik
        initialValues={{
          keyValue: value,
        }}
        validationSchema={Yup.object().shape({
          keyValue: Yup.string()
            .required('Reset key is required')
            .test('passwords-match', "Can't enter symbol", (password) => {
              return /^[a-zA-Z0-9]*$/.test(value);
            }),
        })}
        onSubmit={onSubmit}
      >
        <Form onChange={onChange} className={styles.root_form}>
          <AppInput
            className={styles.form_input}
            name='keyValue'
            type='text'
            placeholder='Reset Key'
            maxLength='6'
          />
          {error && <p className={styles.forgetPassword_error}>{error}</p>}
          <div className={styles.button__group}>
            <Button
              className={styles.form_button_2}
              variant='dark'
              type='submit'
            >
              VERIFY
            </Button>
            <Button
              className={styles.form_button_2}
              variant='dark'
              type='submit'
              onClick={onResendButtonPress}
            >
              RESEND CODE
            </Button>
          </div>
        </Form>
      </Formik>
      <div className={styles.navigate_back_div}>
        <Button variant='link' onClick={handleGoBack}>
          SIGN IN
        </Button>
      </div>
    </div>
  );
};
export default VerifyKey;
