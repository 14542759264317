export const authSuccess = (userData) => {
  return {
    type: AUTH_SUCCESS,
    payload: {
      ...userData,
    },
  };
};

export const getUserDetails = () => {
  return {
    type: GET_USER_DETAILS,
    payload: {},
  };
};

export const getUserDetailsSuccess = (userData) => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: {
      ...userData,
    },
  };
};

export const getUserDataFailure = () => {
  return {
    type: GET_USER_DETAILS_FAILURE,
    payload: {},
  };
};

export const initiateAuthTimeout = (payload) => {
  return {
    type: INITIATE_AUTH_TIMEOUT,
    payload: {
      ...payload,
    },
  };
};

export const initiateLogout = () => {
  return {
    type: INITIATE_LOGOUT,
    payload: {},
  };
};

export const initiateLogoutSuccess = () => {
  return {
    type: INITIATE_LOGOUT_SUCCESS,
    payload: {},
  };
};

export const AUTH_SUCCESS = 'AUTH_SUCCESS';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE';

export const INITIATE_AUTH_TIMEOUT = 'INITIATE_AUTH_TIMEOUT';

export const INITIATE_LOGOUT = 'INITIATE_LOGOUT';
export const INITIATE_LOGOUT_SUCCESS = 'INITIATE_LOGOUT_SUCCESS';
