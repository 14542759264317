import React from 'react';
import { Field, ErrorMessage } from 'formik';

import useComponentState from '../../utils/useComponentState';

import styles from './AppInput.module.scss';

const AppInput = (props) => {
  const {
    name,
    label,
    type,
    placeholder,
    loading,
    containerStyle,
    password,
    max,
    ...otherProps
  } = props;

  const [state, setState] = useComponentState({
    isVisible: false,
  });

  const { isVisible } = state;

  const handleOnPasswordToggle = () => {
    setState({ isVisible: !isVisible });
  };

  return (
    <div className={styles.form__group} style={containerStyle}>
      <span className={styles.form__label}>{label}</span>
      <div className={styles.input__group}>
        <Field
          name={name}
          type={!password ? type : isVisible ? 'text' : 'password'}
          placeholder={placeholder}
          className={styles.form__input}
          disabled={loading}
          maxLength={[
            max,
            placeholder + "Can't be longer than" + max + 'characters',
          ]}
          {...otherProps}
        />
        {password && (
          <button
            type='button'
            className={styles.toggle__container}
            onClick={handleOnPasswordToggle}
          >
            <i className={isVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
          </button>
        )}
      </div>
      <ErrorMessage name={name}>
        {(message) => <span className={styles.form__error}>{message}</span>}
      </ErrorMessage>
    </div>
  );
};

AppInput.defaultProps = {
  containerStyle: {},
  password: false,
};

export default AppInput;
