import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { Loading, PageHeader } from '../../components';
import DataTable from '../../components/DataTable/DataTable';
import useComponentState from './../../utils/useComponentState';
import { getAllUserDetails, filterUser } from './../../api/user';
import Filter from './../../components/filter/filter.component';

import styles from './userDetails.module.scss';

const UserDetails = (props) => {
  const [state, setState] = useComponentState({
    tableHeading: {
      column: ['First Name', 'Last Name', 'Email', 'Mobile Number'],
    },
    userData: [],
    userLoadingError: '',
    loading: false,
    filterLoading: false,
  });
  const {
    tableHeading,
    userData,
    userLoadingError,
    loading,
    filterLoading,
  } = state;

  useEffect(() => {
    getUserDetails();
    //eslint-disable-next-line
  }, []);

  const getUserDetails = async () => {
    setState({ loading: true, userData: [], userLoadingError: '' });
    try {
      const response = await getAllUserDetails(props.auth.auth.token);
      setState({ userData: response.users });
    } catch (error) {
      setState({
        userLoadingError: 'Oops, something went wrong. Please try again',
      });
    }
    setState({ loading: false });
  };

  const handleDownload = async () => {
    handleExcelSheetDownload(userData);
  };

  const handleUserDetailsFilter = async (data) => {
    try {
      setState({ filterLoading: true });
      const result = await filterUser(props.auth.auth.token, data);
      setState({ userData: result.users });
    } catch (e) {}
    setState({ filterLoading: false });
  };

  const handleExportDate = (data) => {
    handleExcelSheetDownload(data);
  };

  const handleExcelSheetDownload = async (users) => {
    const excelDate = users.map((user) => {
      const userDetails = {
        'First Name': user.firstName,
        'Last Name': user.lastName,
        Email: user.email,
        mobileNo: user.mobileNo,
      };
      return userDetails;
    });

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelDate);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'User Details' + fileExtension);
  };

  return (
    <div className={styles.root_div}>
      {loading ? (
        <Loading text='Loading' />
      ) : (
        <div>
          <PageHeader text='User Details' />
          {userLoadingError ? (
            <p>{userLoadingError}</p>
          ) : (
            <div>
              <div className={styles.filter_btn_group}>
                <div className={styles.filter_btn}>
                  <Filter
                    title='User'
                    userDetailsFilter
                    filterData={userData}
                    filterLoading={filterLoading}
                    submitFilterDate={handleUserDetailsFilter}
                    handleExportDate={handleExportDate}
                  />
                </div>
                <Button onClick={handleDownload}>Download</Button>
              </div>

              <DataTable
                tableData={userData}
                tableheading={tableHeading}
                handleOnClick={() => {}}
                tag='userDetails'
                fetchError=''
                searchFilterLoading={false}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(UserDetails);
